import React from "react";
import RightSidebar from "../../components/Social/RightSideBar";
import LoginPage from "../Login";
import { useSelector } from "react-redux";


import 'stream-chat-react/dist/css/v2/index.css';
import ChatSystem from "../../components/Social/ChatSystem";


const ChatPage = () => {

    const { isAuthenticated } = useSelector((state) => state.user);

    return (
        <>
            {!isAuthenticated ? (
                <LoginPage />
            ) : (
                <div className="my-6 grid grid-cols-1 md:grid-cols-5" style={{ maxHeight: "100vh" }}>
                    <div className=" hidden md:block">

                    </div>
                    <ChatSystem />
                    <div className="pr-6 rightsidebar">
                        <RightSidebar />
                    </div>
                </div>
            )}
        </>
    );


};


export default ChatPage;
