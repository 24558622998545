import { Button } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import React, { useEffect } from "react";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Loader from "../Layout/Loader";
import axios from "axios";
import { server } from "../../server";
import { useState } from "react";
import Cookies from 'js-cookie';
import { AiOutlineEdit } from "react-icons/ai";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { sub } from "date-fns";
const AdminListTags = ({ type }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Retrieve the token from the cookie
        const token = Cookies.get('token');
        // Set up the authorization header with the token
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        };
        axios.get(`${server}/${type}/tags`, config).then((res) => {
            setData(res.data.tags);
            setLoading(false);
        })
    }, [type]);

    let columns = [
        { field: "id", headerName: "Tag Id", minWidth: 150, flex: 0.7 },
        {
            field: "name",
            headerName: "Name",
            minWidth: 180,
            flex: 1,
        }
    ];
    columns.push(
        {
            field: "Edit",
            flex: 0.4,
            minWidth: 60,
            headerName: "",
            type: "number",
            sortable: false,
            align: 'center', // Center align the column content
            headerAlign: 'center', // Center align the column header
            disableColumnMenu: true, // Disable the column menu for this column
            renderCell: (params) => {
                return (
                    <>
                        <Link to={`/admin/${type}/tags/${params.id}`}>
                            <Button >
                                <AiOutlineEdit size={20} />
                            </Button>
                        </Link>
                    </>
                );
            },
        },
    )
    const row = [];

    data &&
        data.forEach((item) => {
            row.push({
                id: item._id,
                name: item.name
            });
        });

    return (
        <div className="w-full bg-white py-4 800px:mx-4 shadow rounded-lg 800px:px-10 my-4 800px:my-0">
            <h1 className="block text-[25px] text-center font-[600] text-[#000000ba] pb-2">
                {type.charAt(0).toUpperCase() + type.slice(1)} Tags
            </h1>
            <div style={{ transform: 'rotateZ(0deg)' }}>
                <DataGrid
                    rows={row}
                    columns={columns}
                    pageSize={5}
                    rowHeight={60}
                    disableSelectionOnClick
                    localeText={{ noRowsLabel: loading ? "Loading... please wait" : 'Nothing yet!' }}
                    autoHeight
                    disableRowSelectionOnClick
                />
            </div>
            <div className="flex justify-center px-4 pt-4">
                <Link to={`/admin/${type}/tags/new`}
                    className="w-full 800px:Cmax-w-[50%]  h-[40px] flex justify-center items-center py-2 px-4 border border-transparent text-xl rounded-full text-white bg-[#1B0035]">
                    Create a new {type.charAt(0).toUpperCase() + type.slice(1)} Tag
                </Link>
            </div>
        </div>
    );
};

export default AdminListTags;