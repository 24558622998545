import { createReducer } from "@reduxjs/toolkit";


const initialState = {
  isAuthenticated: false,
  loading: true,
  unreadCount: 0,
  unreadPlatformNotificationsCount: 0,
};

export const userReducer = createReducer(initialState, {

  UpdateUnreadCount: (state, action) => {
    state.unreadCount = action.unreadCount;
  },
  updateUserFromStream: (state, action) => {
    state.unreadPlatformNotificationsCount = action.unreadPlatformNotificationsCount;
  },

  LoadUserSuccess: (state, action) => {
    state.isAuthenticated = true;
    state.loading = false;
    state.user = action.payload;
    state.error = null;
    state.unreadCount = action.unreadCount;
    state.unreadPlatformNotificationsCount = action.unreadPlatformNotificationsCount;
    state.chatClient = action.chatClient;
  },
  LoadUserFail: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },

  // update user information
  updateUserInfoRequest: (state) => {
    state.loading = true;
  },
  updateUserInfoSuccess: (state, action) => {
    state.loading = false;
    state.user = action.payload;
  },
  updateUserInfoFailed: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },

  // get all users --- admin
  getAllUsersRequest: (state) => {
    state.usersLoading = true;
  },
  getAllUsersSuccess: (state, action) => {
    state.usersLoading = false;
    state.users = action.payload;
  },
  getAllUsersFailed: (state, action) => {
    state.usersLoading = false;
    state.error = action.payload;
  },
  clearErrors: (state) => {
    state.error = null;
  },

});
