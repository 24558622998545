import { Button } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import React, { useEffect } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import { useState } from "react";
import Cookies from 'js-cookie';
import { AiOutlineEdit } from "react-icons/ai";

const ListOfferings = ({ type }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    //const [parentData, setParentData] = useState([]);
    //const { parentCategoryId } = useParams();
    const { user, isAuthenticated } = useSelector((state) => state.user);
    useEffect(() => {
        setLoading(true);
        setData([]);
        if (isAuthenticated) {
            // Retrieve the token from the cookies
            const token = Cookies.get('token');

            // Set up the authorization header with the token
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            axios.get(`${server}/${type}/user/offerings/${user?._id}`, config).then((res) => {
                if (type == "services") {
                    setData(res.data.serviceOfferings);
                } else if (type == "portfolio") {
                    setData(res.data.portfolios);
                }
                setLoading(false);
            })
        }

    }, [isAuthenticated, type, user]);

    let columns = [
        {
            field: "title",
            headerName: type.charAt(0).toUpperCase() + type.slice(1),
            minWidth: 180,
            flex: 1,
            sortable: false,
            disableColumnMenu: true, // Disable the column menu for this column
            renderCell: (params) => (
                <div style={{
                    whiteSpace: 'normal',
                    overflow: 'hidden',
                    lineHeight: '1.1',
                }}>
                    <a href={type == "portfolio" ? `/profile/${user?._id}/portfolio/${params.id}` : `/services/?id=${params.id}`} target="_blank">
                        {params.value}
                    </a>
                </div>
            )
        },
        {
            field: "Published",
            headerName: "",
            minWidth: 50,
            flex: 0.3,
            align: 'center',
            headerAlign: 'center',
            hide: type !== "services",
            disableColumnMenu: true,
        },
        {
            field: "Edit",
            flex: 0.4,
            minWidth: 60,
            headerName: "",
            type: "number",
            sortable: false,
            align: 'center', // Center align the column content
            headerAlign: 'center', // Center align the column header
            disableColumnMenu: true, // Disable the column menu for this column
            renderCell: (params) => {
                return (
                    <Link to={`/manage-${type}/${params.id}`}>
                        <Button >
                            <AiOutlineEdit size={20} />
                        </Button>
                    </Link>
                );
            },
        },
    ];
    const row = [];

    data &&
        data.forEach((item) => {
            row.push({
                id: item._id,
                title: item.title,
                Published: item?.published ? "Yes" : "No",
            });
        });

    return (
        <div className="w-full bg-white py-4 800px:mx-4 shadow rounded-lg 800px:px-10 my-4 800px:my-0">
            <h1 className="block text-[25px] text-center font-[600] text-[#000000ba] pb-2">
                My {type}
            </h1>
            <div style={{ transform: 'rotateZ(0deg)' }}>
                <DataGrid
                    rows={row}
                    columns={columns}
                    pageSize={5}
                    rowHeight={60}
                    disableSelectionOnClick
                    localeText={{ noRowsLabel: loading ? "Loading... please wait" : 'Nothing yet!' }}
                    autoHeight
                    disableRowSelectionOnClick
                />
            </div>
            <div className="flex justify-center px-4 pt-4">
                <Link to={`/manage-${type}/new`}
                    className="w-full 800px:max-w-[50%]  h-[40px] flex justify-center items-center py-2 px-4 border border-transparent text-xl rounded-full text-white bg-[#1B0035]">
                    {type === "services" ? "Offer a new service" : "Create a new portfolio"}
                </Link>
            </div>
        </div>
    );
};

export default ListOfferings;
