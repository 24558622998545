import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import styles from "../../styles/styles";
import Ratings from "./../Reviews/Ratings.jsx";
import { BiSolidBadgeCheck } from "react-icons/bi";
import Tooltip from '@atlaskit/tooltip';
import Markdown from "react-markdown";
import Button from '@material-ui/core/Button';
import ShareModal from "../Social/ShareModal";
import { useRef } from "react";

const ServiceOfferingCard = ({ offering, currentUrlTags, urlParams, preventScrollReset=false }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const windowWidth = useRef(window.innerWidth);
    return (
        <Link to={`/services/${currentUrlTags ?? ""}?id=${offering._id}${!urlParams ? "&source=search" : ""}`} state={{ from: location.pathname, search: location.search }} preventScrollReset={windowWidth.current >= 800 && preventScrollReset} className={`flex w-full bg-white 800px:rounded-lg p-2 cursor-pointer relative shadow-sm border  max-w-[600px] mx-auto ${urlParams?.get("id") == offering._id ? "border-[#500696]" : "border-gray-200"}`}>
            <div className="position-relative w-full">
                <h2 className=" text-xl font-medium p-1 text-center">
                    {offering.title.length > 100 ? offering.title.slice(0, 100) + "..." : offering.title}

                </h2>
                <div className=" flex items-center justify-between">
                    <span className="flex items-center">
                        <div className="relative">
                            <img
                                src={`https://res.cloudinary.com/dljapfuhk/image/upload/ar_1.0,c_thumb,g_auto,f_auto/r_max/v1703309315/${offering.account.avatar?.public_id}.jpg`}
                                className="w-[45px] min-w-[45px] rounded-full mr-[10px]" alt=""
                            />
                            {offering?.account?.online && (<div className="absolute bg-green-500 w-3 h-3 rounded-full mr-2 top-0 right-0"></div>)}
                        </div>
                        <div>
                            <div className="flex items-center text-left">
                                <h5 className={`${styles.shop_name}`}>{offering.account.name ?? "Loading..."}</h5>
                                {offering.account.isPremium && (<BiSolidBadgeCheck className="" size="20" color="#1B0035" />)}
                            </div>
                            {offering.account.title ?
                                (<div className="text-sm  text-[#1B0035] font-medium my-[-5px] text-left">
                                    {offering.account.title || ' '}
                                </div>) : ' '}

                        </div>
                    </span>
                    <Ratings rating={offering.averageRating ?? 0} />

                </div>
                <div className="my-3 flex flex-wrap gap-y-2">
                    {offering.tags.map((tag, index) => (
                        <Tooltip key={index} content={tag.description}>
                            <span className="inline-block rounded-full px-3 py-1 text-sm  mr-2 border-[1px] border-[#500696] ">{tag.name}</span>
                        </Tooltip>
                    ))}
                </div>
                <div className=" ">
                </div>
                <div className="flex-none ml-5 mb-3">
                    <Markdown className="text-gray-700 prose my-2 max-w-full line-clamp-3"
                    disallowedElements={["a"]}>
                        {offering.description}</Markdown>
                </div>
                <div className="absolute bottom-0 left-0 ml-5 mb-3">
                    <h5 className={``}>From: ${offering.startingPrice}</h5>
                </div>
                <div className="h-[1lh]"></div>
                <div className="absolute bottom-0 right-0 mr-5 mb-3">
                    <h5 className={``}> {offering.viewCount >= 1000
                        ? (offering.viewCount / 1000).toFixed(1) + " K"
                        : offering.viewCount} views </h5>
                </div>
            </div>
        </Link>
    );
};

export default ServiceOfferingCard;