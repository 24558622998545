import React, { useState, useEffect } from "react";
import axios from "axios";
import { server } from "../server";
import { Button } from "@material-ui/core";
import CompactProfile from "../components/Social/CompactProfile";
import { useSelector } from "react-redux";
import { FaChevronDown } from 'react-icons/fa';
import { Drawer, Box, List } from '@material-ui/core';
import { FaMagnifyingGlass } from "react-icons/fa6";
import { AiOutlineTeam } from "react-icons/ai";
import { Fragment } from "react";
import Cookies from 'js-cookie';
import formatDistance from "date-fns/formatDistance";


const Search = () => {
    const [notifications, setNotifications] = useState({});
    const { isAuthenticated } = useSelector((state) => state.user);
    const { user } = useSelector((state) => state.user);
    const [notificationCategory, setNotificationCategory] = useState("everything");
    const [mobileMenu, setMobileMenu] = useState(false);

    useEffect(() => {
        // Retrieve the token from the cookies
        const token = Cookies.get('token');

        // Set up the authorization header with the token
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        };
        axios.get(`${server}/user/notifications/${notificationCategory}`, config).then((res) => {
            setNotifications(res.data.events);
        });
    }, [notificationCategory]);
    // if (!isAuthenticated) {
    //     return <LoginPage />
    // }
    const menuItems = [
        { icon: FaMagnifyingGlass, text: "Everything" },
        { icon: AiOutlineTeam, text: "Follows" },
        { icon: AiOutlineTeam, text: "Likes" },
        { icon: AiOutlineTeam, text: "Comments" },
    ];

    const NotificationCategoryButtons = ({ notificationCategory, setNotificationCategory }) => {
        const baseStyle = "h-[40px]flex items-center py-2 px-4 border text-xl rounded-full";
        const activeStyle = "bg-[#1B0035] text-white border-transparent";
        const inactiveStyle = "border-[#1B0035] text-[#1B0035] bg-white";

        return (
            <div className="flex-row justify-center pt-3 800px:flex hidden">
                {menuItems.map((category, index) => (
                    <button
                        key={`${category.text}-desktop`}
                        className={`${baseStyle} ${index !== 0 ? 'ml-4 ml-1' : ''} ${notificationCategory === category.text.toLowerCase() ? activeStyle : inactiveStyle}`}
                        onClick={() => { setNotificationCategory(category.text.toLowerCase()) }}
                    >
                        {category.text.charAt(0).toUpperCase() + category.text.slice(1)}
                    </button>
                ))}
            </div>
        );
    };

    const toggleDrawer =
        (open) =>
            (event) => {
                if (
                    event.type === 'keydown' &&
                    ((event).key === 'Tab' ||
                        (event).key === 'Shift')
                ) {
                    return;
                }

                setMobileMenu((oldState) => !oldState);
            };

    const activeItem = menuItems.find(item => item.text.toLowerCase() === notificationCategory);
    const renderMenuItem = (item) => (
        <div className="flex items-center mb-8" onClick={() => setNotificationCategory(item.text.toLowerCase())}>
            <item.icon size={20} color={activeItem === item.text ? "#1B0035" : ""} />
            <span className={`pl-3 ${activeItem === item.text ? "text-[#1B0035]" : ""}`}>{item.text}</span>
        </div>
    );


    return (
        <>
            <div className=" mx-auto">
                <div className="py-6 max-w-[700px] mx-auto min-h-[100svh]">
                    <div className="max-w-[90%] mx-auto p-4 bg-white shadow rounded">

                        <NotificationCategoryButtons notificationCategory={notificationCategory} setNotificationCategory={setNotificationCategory} />

                        <div className="800px:hidden">
                            <Button onClick={toggleDrawer(true)} className="!bg-white w-full shadow !justify-around items-center !mt-2">
                                <span className="flex items-center">
                                    <activeItem.icon size={20} />
                                    <span className="pl-3 ">{activeItem?.text}</span>
                                    <FaChevronDown className="ml-3 " />
                                </span>

                            </Button>
                            <Drawer open={mobileMenu} onClose={toggleDrawer(false)} anchor="top">
                                <Box role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
                                    <List>
                                        <div className="bg-white p-4 pt-4">
                                            {menuItems.map((item, index) => {
                                                return (<Fragment key={item.text}>
                                                    {renderMenuItem(item)}
                                                </Fragment>
                                                );
                                            })}
                                        </div>
                                    </List>
                                </Box>
                            </Drawer>
                        </div>

                    </div>
                    {/* Loop through all notifications and render them using various functions */}
                    <div className={`800px:max-w-[90%] mx-auto mt-4 w-full bg-white shadow rounded-lg py-4 800px:p-4 space-y-2 `}>
                        {notifications.length > 0 ? (
                            notifications.map((notification) => {
                                if (isAuthenticated && user.blocked?.includes(notification.actor._id)) return null;
                                notification.timeDiff = formatDistance(new Date(notification.createdAt), new Date());
                                return (
                                    <div className="p-2 forum-post" key={notification._id}>
                                        <CompactProfile showFollowButton={notification.type == "follow" ? true : false} userId={notification.actor._id} userObject={notification.actor} notificationText={notification.displayText} notificationDateDiff={notification.timeDiff} customButton={notification.customButton} />
                                    </div>
                                );
                            }
                            )) : <div className="text-center text-lg">
                            No notifications found
                        </div>
                        }
                    </div>
                </div>
            </div >
        </>
    );
};

export default Search;
