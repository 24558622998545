import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Button, TextareaAutosize } from "@material-ui/core";
import { toast } from "react-toastify";
import axios from "axios";
import { server } from "../../server";
import Cookies from 'js-cookie';
import { FaArrowLeft } from "react-icons/fa6";
import ProfileSideBar from "../../components/Profile/ProfileSidebar";
import { useLoaderData } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";
import LoginPage from "../Login";
import { useLocation } from "react-router-dom";


const InvoiceCRUD = () => {
    const loaderData = useLoaderData();
    const { user, isAuthenticated, loading } = useSelector((state) => state.user);
    const { invoiceId } = useParams();
    const [title, setTitle] = useState("");
    const [overview, setOverview] = useState("");
    const [contact_name, setContactName] = useState("");
    const [contact_email, setContactEmail] = useState("");
    const [contact_phoneNumber, setContactPhoneNumber] = useState("");
    const [contact_address, setContactAddress] = useState("");
    const [deliverables, setDeliverables] = useState([{ title: "", overview: "", costInCAD: 0 }]);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (invoiceId !== "new") {
            const invoice = loaderData.invoice;
            setTitle(invoice.title)
            setOverview(invoice.overview);
            setContactName(invoice.contactInfo.name);
            setContactEmail(invoice.contactInfo.email);
            setContactPhoneNumber(invoice.contactInfo.phoneNumber);
            setContactAddress(invoice.contactInfo.address);
            setDeliverables(invoice.deliverables);
        }
    }, [invoiceId, loaderData]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!user) return toast.error("Please login!");
            if (!title) return toast.error("A title is required!");
            // all deliverables must be numbers
            if (deliverables.some(deliverable => isNaN(deliverable.costInCAD))) {
                return toast.error("All deliverables must have an associated cost");
            }
            // all deliverables most cost more than one dollar
            if (deliverables.some(deliverable => deliverable.costInCAD < 1)) {
                return toast.error("All deliverables must cost more than one dollar");
            }

            const token = Cookies.get('token');
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            await axios.put(`${server}/invoices/my-invoices/${invoiceId}`, {
                title: title,
                overview: overview,
                contact_name: contact_name,
                contact_email: contact_email,
                contact_phoneNumber: contact_phoneNumber,
                contact_address: contact_address,
                deliverables: deliverables,
            }, config).then((res) => {
                toast.success(res.data.message);
                navigate(`/invoice/${res.data.invoiceId}`, { replace: true, state: location.state });
            }).catch((err) => {
                toast.error("Failed to save invoice");
                console.log(err);
            })
        } catch (err) {
            toast.error("Failed to save invoice")
            console.log(err);
        }
    };

    const handleAddDeliverable = () => {
        setDeliverables([...deliverables, { title: "", overview: "", costInCAD: 0 }]);
    };

    const handleRemoveDeliverable = (index) => {
        const newDeliverables = deliverables.filter((_, i) => i !== index);
        setDeliverables(newDeliverables);
    };

    const handleDeliverableChange = (index, field, value) => {
        const newDeliverables = deliverables.map((deliverable, i) =>
            i === index ? { ...deliverable, [field]: value } : deliverable
        );
        setDeliverables(newDeliverables);
    };

    const goBackToManageInvoices = () => {

        // Check if the previous path matches the desired path
        if (location.state?.from == "ManageInvoices") {
            // If it does, go back
            navigate(-1);
        } else {
            // If it doesn't, navigate to the desired path
            navigate(`/manage-invoices/`);
        }
    };
    if (!isAuthenticated && !loading) {
        return <LoginPage />
    }
    return (
        <div className="w-[90%] mx-auto 800px:flex py-10">
            <div>
                <ProfileSideBar active={`manage-invoices`} />
            </div>

            <div className="w-full bg-white py-4 800px:mx-4 shadow rounded-lg px-4  my-4 800px:my-0">
                <div className="flex justify-between mt-2 mb-5 ">
                    <button onClick={goBackToManageInvoices} className="text-[#1B0035] flex items-center"><FaArrowLeft color="#1B0035" className="pr-1" /> Back to invoices</button>
                </div>
                <h1 className="text-center text-2xl mb-5">{invoiceId === "new" ? "Create New Invoice" : "Edit Invoice"}</h1>
                <label className="pb-2">
                    Title <span className="text-red-500">*</span>
                </label>
                <input
                    type="text"
                    name="title"
                    value={title}
                    className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder="Enter your invoice title..."
                />
                <br />
                <h1 className="text-center text-2xl mb-5">Contact info</h1>
                <p className="text-sm font-[200]">
                    This information will be included in the invoice. It must be accurate and truthfully completed for tax & dispute resolution purposes. <br />ConnectSiimply reserves the right to not pay any invoices that contain misleading or false information.
                </p>
                <br />

                <label className="pb-2">
                    Contact Name
                </label>
                <input
                    type="text"
                    name="contact_name"
                    value={contact_name}
                    className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    onChange={(e) => setContactName(e.target.value)}
                    placeholder="name..."
                />
                <br />
                <label className="pb-2">
                    Contact Email
                </label>
                <input
                    type="email"
                    name="contact_email"
                    value={contact_email}
                    className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    onChange={(e) => setContactEmail(e.target.value)}
                    placeholder="email..."
                />
                <br />
                <label className="pb-2">
                    Contact Phone Number
                </label>
                <input
                    type="text"
                    name="contact_phoneNumber"
                    value={contact_phoneNumber}
                    className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    onChange={(e) => setContactPhoneNumber(e.target.value)}
                    placeholder="phone number..."
                />
                <br />
                <label className="pb-2">
                    Contact Address
                </label>
                <input
                    type="text"
                    name="contact_address"
                    value={contact_address}
                    className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    onChange={(e) => setContactAddress(e.target.value)}
                    placeholder="address..."
                />
                <br />
                <h1 className="text-center text-2xl mb-5">Project details</h1>

                <label className="pb-2">
                    Project Overview (markdown is supported)
                </label>
                <TextareaAutosize
                    name="overview"
                    value={overview}
                    className="p-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    onChange={(e) => setOverview(e.target.value)}
                    placeholder="Overview..."
                    minRows={3}
                />
                <br />
                <h1 className="text-center text-2xl mb-5">Deliverables</h1>
                {deliverables.map((deliverable, index) => (
                    <div key={index} className="mb-4 border p-4 rounded">
                        <div className="w-full flex justify-end cursor-pointer">
                            <RxCross1 size={25} onClick={() => handleRemoveDeliverable(index)} />
                        </div>
                        <label className="pb-2">
                            Deliverable Title
                        </label>
                        <input
                            type="text"
                            name="deliverable_title"
                            value={deliverable.title}
                            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            onChange={(e) => handleDeliverableChange(index, "title", e.target.value)}
                            placeholder="Title..."
                        />
                        <br />
                        <label className="pb-2">
                            Deliverable Overview (markdown is supported)
                        </label>
                        <TextareaAutosize
                            name="deliverable_overview"
                            value={deliverable.overview}
                            className="p-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            onChange={(e) => handleDeliverableChange(index, "overview", e.target.value)}
                            placeholder="Overview..."
                            minRows={3}
                        />
                        <br />
                        <label className="pb-2">
                            Cost in CAD
                        </label>
                        <span className="flex flex-row items-center text-xl mt-2">$<input
                            type="text"
                            name="deliverable_cost"
                            value={deliverable.costInCAD}
                            className="ml-1 appearance-none block w-1/3 px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            onChange={(e) => handleDeliverableChange(index, "costInCAD", e.target.value)}
                            placeholder="Cost..."
                        />
                        </span>

                    </div>
                ))}
                <button
                    className="mt-5 800px:max-w-[50%] mx-auto h-[20px] flex justify-center items-center py-4 px-4 border border-transparent text-md rounded-full text-white bg-[#1B0035]"

                    onClick={handleAddDeliverable}
                >
                    Add Deliverable
                </button>
                <br />
                Total invoice amount:

                <div className="text-xl ">
                    ${
                        deliverables.reduce((acc, curr) => {
                            const cost = parseFloat(curr.costInCAD);
                            return acc + (isNaN(cost) ? 0 : cost);
                        }, 0).toFixed(2)
                    }
                </div>
                <div className="text-sm ">
                    ConnectSiimply keeps 10% of this (${(deliverables.reduce((acc, curr) => {
                        const cost = parseFloat(curr.costInCAD);
                        return acc + (isNaN(cost) ? 0 : cost);
                    }, 0) / 10).toFixed(2)}) to help cover credit card proccessing fees & other operational costs
                </div>

                <button
                    id="profile-update-submit-button"
                    onClick={handleSubmit}
                    className="mt-5 w-full 800px:max-w-[50%] mx-auto h-[40px] flex justify-center items-center py-2 px-4 border border-transparent text-xl rounded-full text-white bg-[#1B0035]"
                >
                    {invoiceId === "new" ? (
                        <>Create new invoice</>
                    ) : (
                        <>Save Changes to invoice</>
                    )}
                </button>

            </div>
        </div>
    );
};

export default InvoiceCRUD;