import React, { useEffect, useState } from "react";
import "./App.css";
import {
  createBrowserRouter,
  Link,
  RouterProvider,
  Outlet,
  ScrollRestoration
} from "react-router-dom";
import Header from "./components/Layout/Header";
import AuthHeader from "./components/Layout/AuthHeader.jsx";
import Footer from "./components/Layout/Footer";
import {
  LoginPage,
  ForgotPasswordPage,
  SetNewPasswordPage,
  SignupPage,
  ActivationPage,
  HomePage,
  ProfilePage,
  ProfileChangePassword,
  SocialHome,
  PostView,
  UserSearch,
  UserNotifications,
  SocialProfile,
  SocialProfilePosts,
  Explore,
  Chat,
  ServicesHome,
  ManageOfferings,
  ServiceCRUD,
  Premium,
  PartnerProgramRedirect,
  PartnerProgramReturn,
  ProfileBlockedUsers,
  ProfileListOfServiceCards,
} from "./routes/Routes.js";
import {
  AdminDashboardPage,
  AdminDashboardUsers,
} from "./routes/AdminRoutes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Store from "./redux/store";
import ProtectedAdminRoute from "./routes/ProtectedAdminRoute";
import { loadUser } from "./redux/actions/user";
import Cookies from "js-cookie";
import { StreamChat } from 'stream-chat';
import { useSelector } from "react-redux";
import FollowerList from "./components/Social/FollowerList.jsx";
import PortfolioCRUD from "./pages/Portfolio/PortfolioCRUD.jsx";
import PortfolioCardList from "./components/Portfolio/PortfolioCardList.jsx";
import ViewPortfolio from "./pages/Portfolio/ViewPortfolio.jsx";
import axios from "axios";
import { server } from "./server";
import AdminCRUDTags from "./pages/Admin/AdminCRUDTags.jsx";
import AdminManageTags from "./pages/Admin/AdminManageTags.jsx";
import InvoiceCRUD from "./pages/Invoices/InvoiceCRUD.jsx";
import ManageInvoices from "./pages/Invoices/ManageInvoices.jsx";
import InvoiceViewer from "./pages/Invoices/InvoiceViewer.jsx";

function PageWrapper({ unreadCount }) {
  return (
    <>
      <Header />
      <Outlet />
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <Footer />
      <ScrollRestoration />
    </>
  )
};

function AuthPageWrapper() {
  return (
    <>
      <AuthHeader />
      <Outlet />
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <Footer />
      <ScrollRestoration />
    </>
  )
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <PageWrapper />,
    errorElement: <div> Sorry this page was not found, click here to go home: <Link to='/'>connectsiimply.com</Link></div>,
    children: [
      {
        path: "/", element: <HomePage />,
        loader: async () => {
          return fetch(`${server}/services/homepage-tags`);
        }
      },
      { path: "/premium", element: <Premium /> },
      {
        path: "/services/:tags?", element: <ServicesHome />,
        loader: async ({ params, request }) => {
          const searchParams = new URL(request.url).searchParams;
          const serviceId = searchParams.get("id");
          let headers = {}
          const token = Cookies.get("token");
          if (token) {
            headers = {
              Authorization: `Bearer ${token}`,
            };
          }
          if (!serviceId) {
            return fetch(`${server}/services/offerings/tags/${encodeURIComponent(params.tags ?? "").replace("%2C", ",") ?? ""}`, { headers });
          }
          return fetch(`${server}/services/offerings/tags/${encodeURIComponent(params.tags ?? "").replace("%2C", ",") ?? ""}?id=${serviceId}`, { headers });
        },
        shouldRevalidate: ({ currentParams, nextParams, nextUrl }) => {
          const searchParams = nextUrl.searchParams;
          const source = searchParams.get("source");
          if (currentParams.tags === nextParams.tags && !source) {
            return false;
          } else {
            return true;
          }
        },
      },
      { path: "/manage-services", element: <ManageOfferings type="services" /> },
      {
        path: "/manage-services/:serviceId?",
        element: <ServiceCRUD />,
        loader: async ({ params }) => {
          let token = Cookies.get("token");
          let headers = {}
          if (token) {
            headers = {
              Authorization: `Bearer ${token}`,
            };
          }
          if (params.serviceId !== "new") {
            return fetch(`${server}/services/offering/${params.serviceId}`, { headers });
          }
          return {}
        }
      },
      {
        path: "/invoice/:invoiceId", element: <InvoiceViewer />,
        loader: async ({ params }) => {
          let token = Cookies.get("token");
          let headers = {}
          if (token) {
            headers = {
              Authorization: `Bearer ${token}`,
            };
          }
          return fetch(`${server}/invoices/invoice/${params.invoiceId}`,{ headers });
        }
      },
      { path: "/manage-invoices", element: <ManageInvoices /> },
      {
        path: "/manage-invoices/:invoiceId?",
        element: <InvoiceCRUD />,
        loader: async ({ params }) => {
          let token = Cookies.get("token");
          let headers = {}
          if (token) {
            headers = {
              Authorization: `Bearer ${token}`,
            };
          }
          if (params.invoiceId !== "new") {
            return fetch(`${server}/invoices/invoice/${params.invoiceId}`, { headers });
          }
          return {}
        }
      },
      { path: "/admin/services/tags", element: <ProtectedAdminRoute><AdminManageTags type="services" /></ProtectedAdminRoute> },
      { path: "/admin/services/tags/:tagId", element: <ProtectedAdminRoute><AdminCRUDTags type="services" /></ProtectedAdminRoute> },
      { path: "/manage-portfolio", element: <ManageOfferings type="portfolio" /> },
      { path: "/manage-portfolio/:portfolioId?", element: <PortfolioCRUD /> },
      { path: "/posts", element: <SocialHome /> },
      { path: "/posts/:id", element: <PostView /> },
      {
        path: "/profile/:id", element: <SocialProfile />,
        loader: async ({ params }) => {
          return fetch(`${server}/user/user-info/${params.id}`);
        },
        children: [{
          path: "posts",
          element: <SocialProfilePosts />,
        },
        {
          path: "services",
          element: <ProfileListOfServiceCards />,
          loader: async ({ params }) => {
            return fetch(`${server}/services/user/offerings/${params.id}`);
          }
        },
        {
          path: "followers",
          element: <FollowerList />,
        },
        {
          path: "portfolio",
          element: <PortfolioCardList />,
          loader: async ({ params }) => {
            return fetch(`${server}/portfolio/user/offerings/${params.id}`);
          }
        },
        {
          path: "portfolio/:portfolioId",
          element: <ViewPortfolio />
        }
        ]
      },
      { path: "/explore", element: <Explore /> },
      { path: "/chat", element: <Chat /> },
      { path: "/profile", element: <ProfilePage /> },
      { path: "/profile/change-password", element: <ProfileChangePassword /> },
      { path: "/profile/blocked-users", element: <ProfileBlockedUsers /> },
      { path: "/admin/dashboard", element: <ProtectedAdminRoute><AdminDashboardPage /> </ProtectedAdminRoute> },
      { path: "/admin-users", element: <ProtectedAdminRoute><AdminDashboardUsers /> </ProtectedAdminRoute> },
      { path: "/profile/partnerProgramRedirect", element: <PartnerProgramRedirect /> },
      { path: "/profile/partnerProgramReturn", element: <PartnerProgramReturn /> },
      { path: "/search", element: <UserSearch /> },
      { path: "/notifications", element: <UserNotifications /> },
      
    ]
  },
  {
    path: "/", element: <AuthPageWrapper />,
    children: [
      { path: "/login", element: <LoginPage /> },
      { path: "/forgot-password", element: <ForgotPasswordPage /> },
      { path: "/forgot-password/:reset_token", element: <SetNewPasswordPage /> },
      { path: "/sign-up", element: <SignupPage /> },
      { path: "/activation/:activation_token", element: <ActivationPage /> },
    ]
  },
]);

const App = () => {
  const { isAuthenticated } = useSelector((state) => state.user);

  useEffect(() => {
    if (!isAuthenticated) {
      Store.dispatch(loadUser());
    }

  }, [isAuthenticated]);

  return <RouterProvider router={router} />;
};

export default App;