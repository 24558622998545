import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import { server } from '../../server';
import {Navigate} from 'react-router-dom';
const PartnerProgramRedirect = () => {

    useEffect(() => {
        const fetchData = async () => {
            // Retrieve the token from the cookies
            const token = Cookies.get('token');
            // Set up the authorization header with the token
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            const response = await fetch(`${server}/partnerProgram/refresh`, config);

        };

        fetchData();
    }, []);

    return (
        <Navigate to="/profile" />
    )
};

export default PartnerProgramRedirect;
