import React from "react";
import {
  AiOutlineLogin, AiOutlineTeam
} from "react-icons/ai";
import { RiLockPasswordLine } from "react-icons/ri";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { RxPerson } from "react-icons/rx";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Cookies from 'js-cookie';
import { ImBlocked } from "react-icons/im";
import { FaDollarSign, FaChevronDown } from "react-icons/fa";
import { Drawer, Button, List, Box } from '@material-ui/core';
import { BiSolidBadgeCheck } from "react-icons/bi";
import { LiaFileInvoiceSolid } from "react-icons/lia";

const ProfileSidebar = ({ active }) => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);

  const logoutHandler = () => {
    // Retrieve the token from the cookies
    const token = Cookies.get('token');
    // Set up the authorization header with the token
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    };
    axios
      .get(`${server}/user/logout`, config)
      .then((res) => {
        Cookies.remove('token');
        Cookies.remove('streamChatUserToken');
        toast.success(res.data.message);
        navigate("/");
        // A reload is used to clear any Javascript variables that are holding sensitive information
        window.location.reload(true);
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };


  const [mobileMenu, setMobileMenu] = React.useState(false);

  const toggleDrawer =
    (open) =>
      (event) => {
        if (
          event.type === 'keydown' &&
          ((event).key === 'Tab' ||
            (event).key === 'Shift')
        ) {
          return;
        }

        setMobileMenu((oldState) => !oldState);
      };

  const menuItems = [
    { to: "/profile", icon: RxPerson, text: "Profile", pageKey: "profile" },
    { to: "/profile/blocked-users", icon: ImBlocked, text: "Blocked Users", pageKey: "blocked-users" },
    { to: "/profile/change-password", icon: RiLockPasswordLine, text: "Change Password", pageKey: "change-password" },
    { to: "https://billing.stripe.com/p/login/6oE9Clgrz9tY32E288", target: "_blank", icon: BiSolidBadgeCheck, text: "Manage Subscription", pageKey: "manage-subscription" },
    { to: "/profile/partnerProgramRedirect", target: "_blank", icon: FaDollarSign, text: "Partner Program", pageKey: "partner-program" },
    { to: "/manage-services/", icon: AiOutlineTeam, text: "My Services", pageKey: "manage-services" },
    { to: "/manage-portfolio/", icon: AiOutlineTeam, text: "My Portfolio", pageKey: "manage-portfolio" },
    { to: "/manage-invoices/", icon: LiaFileInvoiceSolid, text: "Invoices", pageKey: "manage-invoices" },
    { to: "/admin/dashboard", icon: MdOutlineAdminPanelSettings, text: "Admin Dashboard", pageKey: "admin-dashboard", admin: true },
    { to: "#", onClick: logoutHandler, icon: AiOutlineLogin, text: "Log out", pageKey: "logout" }
  ];

  const renderMenuItem = (item) => (
    <div className="flex items-center mb-8" onClick={item.onClick}>
      <item.icon size={20} color={active === item.pageKey ? "#500696" : ""} />
      <span className={`pl-3 ${active === item.pageKey ? "text-[#500696]" : ""}`}>{item.text}</span>
    </div>
  );

  const activeItem = menuItems.find(item => item.pageKey === active);

  return (
    <>
      <div className="800px:hidden">
        <Button onClick={toggleDrawer(true)} className="!bg-white w-full shadow !justify-around items-center">
          <span className="flex items-center">
            <activeItem.icon size={20} />
            <span className="pl-3 ">{activeItem.text}</span>
            <FaChevronDown className="ml-3 " />
          </span>

        </Button>
        <Drawer open={mobileMenu} onClose={toggleDrawer(false)} anchor="top">
          <Box role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
            <List>
              <div className="bg-white p-4 pt-4">
                {menuItems.map((item, index) => {
                  if (item.admin && (!user || user?.role !== "Admin")) return null;
                  if (item.pageKey === "manage-subscription" && user?.premiumExpirationDate < new Date().getTime() / 1000) return null;
                  return (
                    <Link key={index} to={item.to} target={item.target}>
                      {renderMenuItem(item)}
                    </Link>
                  );
                })}
              </div>
            </List>
          </Box>
        </Drawer>
      </div>

      <div>
        <div className="min-w-[300px] mt-0 bg-white shadow rounded-lg p-4 pt-8 800px:block hidden">
          {menuItems.map((item, index) => {
            if (item.admin && (!user || user?.role !== "Admin")) return null;
            if (item.pageKey === "manage-subscription" && user?.premiumExpirationDate < new Date().getTime() / 1000) return null;
            return (
              <Link key={index} to={item.to} target={item.target}>
                {renderMenuItem(item)}
              </Link>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ProfileSidebar;