import React from "react";
import LoginPage from "../Login";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ListOfferings from "../../components/Offerings/ListOfferings";
import ProfileSideBar from "../../components/Profile/ProfileSidebar";
const ManageOfferings = ({ type }) => {
  const { user, isAuthenticated } = useSelector((state) => state.user);

  if (!isAuthenticated) {
    return <LoginPage />
  }

  return (
    <div>
      <div className={`w-[90%] mx-auto 800px:flex py-10`}>
        <ProfileSideBar active={`manage-${type}`} />

        <ListOfferings type={type} />
      </div>
    </div>
  );
};
export default ManageOfferings;
