import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import { server } from '../../server';
import { toast } from 'react-toastify';

const PartnerProgramRedirect = () => {

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Retrieve the token from the cookies
        const token = Cookies.get('token');
        // Set up the authorization header with the token
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await fetch(`${server}/partnerProgram/refresh`, config);
        const data = await response.json();

        // Check the response and redirect based on the result
        if (data.accountLink) {
          window.location.href = data.accountLink;
        } else if (data.loginLink) {
          window.location.href = data.loginLink;
        } else {
          // Handle error
          toast.error(data.message);
          console.log("unknown error");
        }
      } catch (error) {
        // Handle error
    
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      {/* This page will redirect to the Stripe account setup page */}
    </div>
  );
};

export default PartnerProgramRedirect;
