import axios from "axios";
import React, { useRef, useState } from "react";
import formatDistance from "date-fns/formatDistance";

import { useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { AiFillPushpin, AiOutlineHeart, AiOutlinePushpin } from "react-icons/ai";
import { AiFillHeart } from "react-icons/ai";
import { server } from "../../server";
import Cookies from 'js-cookie';
import DeleteModal from "./DeleteModal";
import ShareModal from "./ShareModal";
import { AiOutlineMessage } from "react-icons/ai";
import { toast } from "react-toastify";
import Button from '@material-ui/core/Button';
import { BiSolidBadgeCheck } from "react-icons/bi";
import {AiFillLike, AiOutlineLike} from "react-icons/ai";

const Comment = ({ post, deletePost, setReplyingTo, updateCommentList, subList = false, mainPostUserId }) => {
  const { user, isAuthenticated } = useSelector((state) => state.user);

  const [userData, setUserData] = useState();
  const [likes, setLikes] = useState(post.likes);
  const [pinned, setPinned] = useState(post.pinned);

  //   const dateStr = formatDistance(new Date(post.createdAt), new Date());



  useEffect(() => {
    const controller = new AbortController();
    try {
      axios.get(`${server}/user/user-info/${post.userId}`, { signal: controller.signal }).then((response,) => {
        setUserData(response.data.user);
      });
    } catch (err) {
      console.log("error", err);
    }
    // Cleanup function
    return () => {
      controller.abort();
    };
  }, [post?.userId]);

  const handleLike = async (e) => {
    e.preventDefault();

    try {
      const token = Cookies.get('token');
      if (!token || !user) {
        toast.error("Please login to like this post");
      }
      // Set up the authorization header with the token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      };
      axios.put(`${server}/posts/${post._id}/like`, {
        id: user._id,
      }, config);
      if (likes.includes(user._id)) {
        setLikes(likes.filter((id) => id !== user._id));
      } else {
        setLikes(likes.concat(user._id));
      }
    } catch (err) {
      console.log("error", err);
    }
  };
  const handlePin = async (e) => {
    e.preventDefault();
    if (user?._id !== mainPostUserId) {
      return;
    }
    try {
      const token = Cookies.get('token');
      if (!token || !user) {
        toast.error("Please login to like this post");
      }
      // Set up the authorization header with the token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      };
      axios.put(`${server}/posts/${post._id}/pin`, {
        id: user._id,
      }, config).then((response) => {
        toast.success(response.data.message);
        // 
        updateCommentList(Date.now());
      });
      setPinned(!pinned);

    } catch (err) {
      console.log("error", err);
    }
  };

  if (isAuthenticated && user.blocked?.includes(userData?._id)) return;
  if (!isAuthenticated || !userData) return; // stall until user is authenticated and userData is fetched
  return (
    <div className="p-2 relative">
      <>
        {(likes.includes(mainPostUserId)) && (
          <div className="flex row items-center align-middle"><AiFillHeart color="1B0035" />  By Author</div>
        )}
        <div className="flex space-x-2 comment-top">

          <Link to={`/profile/${userData?._id}/posts`} style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={`https://res.cloudinary.com/dljapfuhk/image/upload/ar_1.0,c_thumb,g_auto,f_auto/r_max/v1703309315/${userData?.avatar?.public_id}.jpg`}
              className="w-[45px] min-w-[45px] rounded-full mr-[10px]" alt="" hidden={!userData} />
            <div className="flex items-center w-full">
              <h3 className="font-bold flex items-center w-full">{userData?.name}</h3>
              {userData?.isPremium && (<BiSolidBadgeCheck className="" size="20" color="#1B0035" />)}

            </div>
          </Link>
          {/* <p> - {dateStr}</p> */}
          {user?._id === post?.userId && (<DeleteModal post={post} postType="Comment" deletePost={deletePost} />)}

          {!subList && (
            <button onClick={handlePin} >
              {pinned ? (
                <AiFillPushpin className="mr-2 my-2 cursor-pointer" color="#1B0035" />
              ) : (user?._id === mainPostUserId && (

                <AiOutlinePushpin className="mr-2 my-2 cursor-pointer" color="#1B0035" />

              ))}
            </button>
          )}


          {/* <ShareModal post={post} />   */}
        </div>
        {/* <div ref={mediaContainerRef}></div> */}
        <div className="flex flex-row justify-between">
          <p style={{ wordWrap: "break-word", marginLeft: "45px" }}>{post.description}</p>


          <button onClick={handleLike} style={{ display: "flex", alignItems: "center" }}>
            {likes.includes(user?._id) ? (
              <AiFillLike className="mx-2 my-2 cursor-pointer " color="#1B0035" />
            ) : (
              <AiOutlineLike className="mx-2 my-2 cursor-pointer " color="#1B0035" />
            )}
            {likes.length || 0}
          </button>
          {/* {post?.commentCount >-1 && (
          <Link to={`/posts/${post?._id}`} style={{ display: "flex", alignItems: "center" }}>

              <AiOutlineMessage className="mr-2 my-2 cursor-pointer ml-[15px]"/>
            
            {post?.commentCount || 0}
          </Link>
          )} */}

        </div>
        <div className={`forum-post-bottom ${subList ? "pb-2":""}`}>
          {!subList && (
            <Button onClick={() => { setReplyingTo([post?._id, userData?.name]) }}>Reply</Button>
          )}
          {/* ) :
          (
          <Button onClick={closeReplyForm}>Close</Button>
          )
          )} */}
        </div>
      </>

    </div>
  );
};

export default Comment;
