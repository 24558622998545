import React, { useState } from "react";
import ProfileSideBar from "../../components/Profile/ProfileSidebar";
import ProfileContent from "../../components/Profile/ProfileContent";
import { useSelector } from "react-redux";
import LoginPage from "../Login";
const ProfilePage = () => {
  const { user, isAuthenticated } = useSelector((state) => state.user);

  if (!isAuthenticated) {
    return <LoginPage />
  }
  return (
    <div>
      <div className={`w-[90%] mx-auto 800px:flex py-10`}>
        <ProfileSideBar active={"profile"} />
        <ProfileContent/>
      </div>
    </div>
  );
};

export default ProfilePage;
