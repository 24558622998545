import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { RxCross1 } from "react-icons/rx";
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import { RxHamburgerMenu } from "react-icons/rx";
import { IoIosPeople } from "react-icons/io";
import { MdOutlineWork } from "react-icons/md";
import { MdOutlineEmojiPeople } from "react-icons/md";
import { FaDollarSign, FaUserGear } from "react-icons/fa6";
import { Backdrop } from "@material-ui/core";
import { IoChatbubbleEllipsesOutline, IoBookmark } from "react-icons/io5";
import { AiOutlineBell } from "react-icons/ai";
import {IoNotificationsOutline} from "react-icons/io5";
import { AiOutlineSearch } from "react-icons/ai";
import axios from "axios";
import { server } from "../../server";
import CompactProfile from "../Social/CompactProfile";
import ServiceOfferingCard from "../../components/Services/ServiceOfferingCard";
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';

const Header = () => {
  const { isAuthenticated, user, loading, unreadCount,unreadPlatformNotificationsCount } = useSelector((state) => state.user);
  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchVisible, setSearchVisible] = useState(false);
  const location = useLocation();
  useEffect(() => {
    // Retrieve the token from the cookies
    const token = Cookies.get('token');

    // Set up the authorization header with the token
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    };

    axios.get(`${server}/search/everything/${searchQuery}?maxResults=4`, config).then((res) => {
      setSearchResults(res.data);
    });
  }, [searchQuery]);

  const toggleDrawer = (newOpen) => () => {
    setSearchVisible(false);
    setOpen(newOpen);
  };
  useEffect(() => {
    // Add or remove class to body based on the 'open' state
    if (open) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");

    }
  }, [open]);

  const menuItems = [
    { to: "/search", icon: AiOutlineSearch, text: "Search" },
    { to: "/services/", icon: IoIosPeople, text: "Hire Talent" },
    { to: "/posts", icon: MdOutlineEmojiPeople, text: "My Feed" },
    { to: "/explore", icon: MdOutlineEmojiPeople, text: "Explore" },
    { to: "/premium", icon: FaDollarSign, text: "Premium" },
    { to: "/profile", icon: FaUserGear, text: "My Account" },
  ];

  const renderMenuItem = (item) => (
    <div className="flex items-center py-4 text-l ml-8" onClick={() => item.onClick}>
      <item.icon size={30} />
      <span className={`pl-3`}>{item.text}</span>
    </div>
  );


  const DrawerList = (
    <Box role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
      <List>
        {menuItems.map((item, index) => {

          return (
            <Link key={index} to={item.to} target={item.target}>
              {renderMenuItem(item)}
            </Link>
          );
        })}
        <div className=" cursor-pointer  800px:hidden  text-center py-4">
          {isAuthenticated ? (
            <Link to={`/profile/${user?._id}/posts`} className="flex justify-center flex-col text-center ">
              <img
                src={`https://res.cloudinary.com/dljapfuhk/image/upload/ar_1.0,c_thumb,g_auto,f_auto/r_max/v1703309315/${user.avatar?.public_id}.jpg`}
                className="h-[55px] w-[55px] rounded-full mx-auto"
                alt=""
                hidden={!user}
              />
              <span>{user?.name}</span>
            </Link>
          ) : (
            loading ? (
              <></>
            ) : (
              <>
                <Link
                  to="/login"
                  className=" h-auto pr-1"
                >
                  Login
                </Link>
                /
                <Link
                  to="/sign-up"
                  className=" h-auto pl-1"
                >
                  Sign up
                </Link>
              </>
            )
          )}
        </div>
      </List>
    </Box>
  );

  return (
    <>
      <div className="h-[70px]">
      </div>
      <div
        className={`shadow-sm fixed flex top-0 left-0 z-20 transition justify-between w-full ${location.pathname=="/" ? "bg-[#FDF8EE]" : "bg-[#fff]"} h-[70px]`}
      >
        <div
          className={`w-11/12 mx-auto relative flex justify-between items-center`}
        >
          <div className="flex flex-row items-center">
            <div className="800px:pl-10 flex flex-row items-center">
              {open ? (
                <RxCross1
                  size={40}
                  className="select-none cursor-pointer"
                  onClick={() => setOpen(false)}
                  color="#1B0035"
                />
              ) : (
                <RxHamburgerMenu
                  size={40}
                  className="select-none cursor-pointer"
                  onClick={() => setOpen(true)}
                  color="#1B0035"
                />
              )}
              <Link to="/" className="  1300px:ml-[80px] 1300px:static absolute 1300px:left-0 left-[50%] 1300px:translate-x-0 translate-x-[-50%] " onClick={toggleDrawer(false)}>
                <img
                  src={require("../../images/new-logo.png")}
                  alt="Logo"
                  height={60}
                  className=" max-h-[50px]"
                />

              </Link>
            </div>
          </div>
          <div className={`absolute left-[50%]  translate-x-[-50%] ${["/", "/search"].includes(location.pathname) ? "hidden":""}`}>
            <div className="1300px:flex flex-col align-stretch items-start items-center w-[600px] h-[70px] hidden">
              <input
                placeholder="Search ConnectSiimply"
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onFocus={() => setSearchVisible(true)}
                className="mt-[15px] w-full appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-[#1B0035]"
              />

              {searchResults.users?.length > 0 && searchVisible &&
                <div className="w-full bg-white shadow rounded-lg pb-2 mt-1">
                  <h1 className="block text-[25px] text-center font-[600] text-[#000000ba] pb-2">
                    Users
                  </h1>
                  {
                    searchResults.users.map((userObject) => {
                      if (isAuthenticated && user.blocked?.includes(userObject._id)) return null;
                      return (
                        <div className="p-2 forum-post" key={userObject._id} onClick={toggleDrawer(false)}>
                          <CompactProfile userId={userObject._id} userObject={userObject} showFollowButton={false} />
                        </div>
                      );
                    })

                  }
                  <Link to={`/search/?query=${searchQuery}`}
                    onClick={() => { setSearchVisible(false); setSearchQuery("") }}
                    className="w-1/2 mx-auto h-[40px] flex justify-center items-center py-2 px-4 border border-transparent text-xl rounded-full text-white bg-[#1B0035]"
                  >
                    See More Results
                  </Link>
                </div>
              }
              {searchResults.services?.length > 0 && searchVisible &&
                <div className={`mt-1 pb-2 w-full bg-white shadow rounded-lg p-4 space-y-2`}>
                  <h1 className="block text-[25px] text-center font-[600] text-[#000000ba] pb-2 800px:col-span-2">
                    Services
                  </h1>
                  {
                    searchResults.services.map((serviceObject) => {
                      return (
                        <div  key={serviceObject._id} onClick={toggleDrawer(false)}>
                          <ServiceOfferingCard offering={serviceObject} />
                        </div>
                      );
                    })
                  }
                  <Link to={`/search/?query=${searchQuery}`}
                    onClick={() => { setSearchVisible(false); setSearchQuery("") }}
                    className="800px:col-span-2 w-1/2 mx-auto h-[40px] flex justify-center items-center py-2 px-4 border border-transparent text-xl rounded-full text-white bg-[#1B0035]"
                  >
                    See More Results
                  </Link>
                </div>
              }
            </div>
          </div>
          <div className="flex justify-end items-center" >
          <Link to="/notifications" className={`800px:mr-5 mr-2 flex ${!isAuthenticated ? "hidden" : ""}`} onClick={toggleDrawer(false)}>
              <IoNotificationsOutline color="#1B0035" size="40" />
              {unreadPlatformNotificationsCount > 0 && (<span className="text-[#1B0035] pl-1">{unreadPlatformNotificationsCount}</span>)}
            </Link>
            <Link to="/chat" className={`800px:mr-5 flex ${!isAuthenticated ? "hidden" : ""}`} onClick={toggleDrawer(false)}>
              <IoChatbubbleEllipsesOutline color="#1B0035" size="40" />
              {unreadCount > 0 && (<span className="text-[#1B0035] pl-1">{unreadCount}</span>)}
            </Link>
            <div className="relative cursor-pointer mr-[15px] hidden 800px:block">
              {isAuthenticated ? (
                <Link to={`/profile/${user?._id}/posts`}>
                  <img
                    src={`https://res.cloudinary.com/dljapfuhk/image/upload/ar_1.0,c_thumb,g_auto,f_auto/r_max/v1703309315/${user.avatar?.public_id}.jpg`}
                    className="h-[55px] w-[55px] rounded-full"
                    alt=""
                    hidden={!user}
                    onClick={toggleDrawer(false)}
                  />
                </Link>
              ) : (
                loading ? (
                  <></>
                ) : (
                  <>
                    <Link
                      to="/login"
                      className="font-[500] text-[#000] text-lg h-auto pr-1 hover:text-[#1B0035]"
                      onClick={toggleDrawer(false)} >
                      Login
                    </Link>
                    /
                    <Link
                      to="/sign-up"
                      className="font-[500] text-[#000] text-lg h-auto hover:text-[#1B0035] pl-1"
                      onClick={toggleDrawer(false)}>
                      Sign up
                    </Link>
                  </>
                )
              )}
            </div>
          </div>
        </div>
      </div>
      <Backdrop open={open || searchVisible} onClick={toggleDrawer(false)} style={{ zIndex: 10, color: '#fff' }} />
      <div className={` duration-200 fixed w-[250px] bg-[#1B0035] text-white p-4 z-10 overflow-y-noscroll h-full ${open ? "left-[0px]" : "left-[-110%]"}`}>
        {DrawerList}
      </div>

    </>
  );
}

export default Header;
