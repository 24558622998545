import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const Thumbnail = ({ media, onMove, onDelete }) => {
  const ref = useRef(null);
  const { publicId, mediaType, index } = media;

  const [, drop] = useDrop({
    accept: 'thumbnail',
    hover(item) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      onMove(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'thumbnail',
    item: () => {
      return { index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  // const src = `https://res.cloudinary.com/dljapfuhk/${mediaType}/upload/c_fill,dpr_1.0,f_webp,g_auto,h_150,q_auto,w_auto/v1/${publicId}`;
  const src = useMemo(() => `https://res.cloudinary.com/dljapfuhk/${mediaType}/upload/c_fill,dpr_1.0,f_webp,g_auto,h_150,q_auto,w_auto/v1/${publicId}`, [publicId, mediaType]);
  return (
    <div
      ref={ref}
      style={{
        position: 'relative',
        height: '150px',
        marginRight: '8px',
        opacity: isDragging ? 0 : 1,
        cursor: 'move',
      }}
    >
      <img src={src} alt="Thumbnail" style={{
        height: '150px', // Fixes the height of the image
        minWidth: 'auto', // Allows the image to maintain its width
        maxWidth: 'none', // Prevents the image from being limited in width
        objectFit: 'cover', // Covers the area, may crop top/bottom if aspect ratio differs
      }} />
      {mediaType === 'video' && (
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          color: 'white',
          fontSize: '24px',
          pointerEvents: 'none',
        }}>▶</div>
      )}
      <button
        onClick={() => onDelete(index)}
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          background: 'none',
          border: 'none',
          color: 'white',
          padding: '0.5em',
          cursor: 'pointer',
        }}
      >✕</button>
    </div>
  );
};

const ServiceUploadWidget = ({ setMedia, media }) => {
  const cloudinaryRef = useRef();
  const widgetRef = useRef();

  const handleSuccess = (result) => {
    const { public_id, resource_type } = result.info;
    setMedia(oldMedia => [...oldMedia, { publicId: public_id, mediaType: resource_type }]);
  };

  const moveMedia = useCallback((dragIndex, hoverIndex) => {
    setMedia((prevMedia) => {
      const newMedia = Array.from(prevMedia);
      const [removed] = newMedia.splice(dragIndex, 1);
      newMedia.splice(hoverIndex, 0, removed);
      return newMedia;
    });
  }, [setMedia]);

  const deleteMedia = useCallback((index) => {
    const newMedia = [...media];
    newMedia.splice(index, 1);
    // setMediaState(newMedia);
    setMedia(newMedia);
  }, [media, setMedia]);

  useEffect(() => {
    cloudinaryRef.current = window.cloudinary;
    widgetRef.current = cloudinaryRef.current.createUploadWidget(
      {
        cloudName: "dljapfuhk",
        uploadPreset: "forum_media",
        thumbnails: false,
        styles: {
          palette: {
            window: "#FDF8EE",
            inactiveTabIcon: "#1B0035",
            tabIcon: "#1B0035",
            link: "#1B0035",
            menuIcons: "#1B0035",
            windowBorder: "#1B0035",
            sourceBg: "#FDF8EE",
            action: "#1B0035",
          }
        }
      },
      (error, result) => {
        if (result.event === "success") {
          handleSuccess(result);
        }
      }
    );
    return () => {
      widgetRef.current.destroy();
    };
  }, []);

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <div className="uploaded-thumbnails pb-2 pt-2" style={{
          display: 'flex',
          overflowX: 'auto', // Enables horizontal scrolling
          flexWrap: 'nowrap', // Prevents the thumbnails from wrapping
          alignItems: 'center', // Vertically centers the thumbnails if they have different heights
          gap: '8px', // Adds some space between thumbnails
          padding: '8px', // Adds some padding inside the container
        }}>
          {media.map((mediaItem, index) => (
            <Thumbnail
              key={mediaItem.publicId}
              media={{ ...mediaItem, index }}
              onMove={moveMedia}
              onDelete={() => deleteMedia(index)}
            />
          ))}
        </div>
      </DndProvider>
      <button

        className="mt-5 w-full 800px:max-w-[50%] mx-auto h-[40px] flex justify-center items-center py-2 px-4 border border-transparent text-xl rounded-full text-white bg-[#1B0035]"
        onClick={(e) => {
          e.preventDefault();
          widgetRef.current.open();
        }}
       
      >
        Upload Media
      </button>
    </>
  );
};

export default ServiceUploadWidget;