import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { DataGrid } from "@material-ui/data-grid";
import { Button, MenuItem, Select, FormControl, InputLabel } from "@material-ui/core";
import { AiOutlineEdit } from "react-icons/ai";
import axios from "axios";
import Cookies from "js-cookie";
import LoginPage from "../Login";
import ProfileSideBar from "../../components/Profile/ProfileSidebar";
import { server } from "../../server";

const ManageInvoices = () => {
    const { user, isAuthenticated,loading } = useSelector((state) => state.user);
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [invoiceType, setInvoiceType] = useState("created");
    const [statusFilter, setStatusFilter] = useState("All");

    useEffect(() => {
        setIsLoading(true);
        setData([]);
        const token = Cookies.get("token");
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const endpoint =
            invoiceType === "created"
                ? `${server}/invoices/created-invoices`
                : invoiceType == "received" ? `${server}/invoices/received-invoices`
                    : `${server}/invoices/admin/invoices`;
        axios.get(endpoint, config).then((res) => {
            setData(res.data.invoices);
            setIsLoading(false);
        });


    }, [invoiceType]);

    useEffect(() => {

        if (statusFilter === "All") {
            setFilteredData(data);
        } else {
            const filtered = data.filter((invoice) => invoice.status === statusFilter);
            setFilteredData(filtered);
        }


    }, [statusFilter, data]);

    const columns = [
        {
            field: "title",
            headerName: "Invoices",
            minWidth: 180,
            flex: 2,
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <div
                    style={{
                        whiteSpace: "normal",
                        overflow: "hidden",
                        lineHeight: "1.1",
                    }}
                >
                    <Link to={`/invoice/${params.id}`} state={{ from: "ManageInvoices" }}>
                        {params.value}
                    </Link>
                </div>
            ),
        },
        {
            field: "status",
            headerName: "Status",
            minWidth: 50,
            flex: 1,
            align: "center",
            headerAlign: "center",
            disableColumnMenu: true,
        },
        {
            field: "Edit",
            hide: invoiceType !== "created",
            flex: 0.5,
            minWidth: 60,
            headerName: "",
            type: "number",
            sortable: false,
            align: "center",
            headerAlign: "center",
            disableColumnMenu: true,
            renderCell: (params) => (
                (params.row.status === "Draft") ?
                    <Link to={`/manage-invoices/${params.id}`}
                        state={{ from: "ManageInvoices" }}>
                        <Button>
                            <AiOutlineEdit size={20} />
                        </Button>
                    </Link>
                    : ""

            ),
        },
    ];

    const rows = filteredData.map((item) => ({
        id: item._id,
        title: item.title,
        status: item.status,
    }));

    if (!isAuthenticated && !loading) {
        return <LoginPage />;
    }

    return (
        <div>
            <div className={`w-[90%] mx-auto 800px:flex py-10`}>
                <ProfileSideBar active={`manage-invoices`} />
                <div className="py-4 800px:py-0 flex flex-col w-full space-y-4 800px:mx-4 ">
                    <div className="w-full bg-white pt-4 shadow rounded-lg 800px:px-10">
                        <h1 className="block text-[25px] text-center font-[600] text-[#000000ba] pb-2">
                            Invoices
                        </h1>
                        <div className="w-full  text-lg flex">
                            <button className={`SocialProfile__NavLink ${invoiceType === "created" ? "active" : ""} ${isAuthenticated && user.role == "Admin" ? "w-1/3" : "w-1/2"}`}
                                onClick={() => setInvoiceType("created")}>
                                Created Invoices</button>
                            <button className={`SocialProfile__NavLink ${invoiceType === "received" ? "active" : ""} ${isAuthenticated && user.role == "Admin" ? "w-1/3" : "w-1/2"}`}
                                onClick={() => setInvoiceType("received")}
                            >Received Invoices</button>
                            {isAuthenticated && user.role === "Admin" && (
                                <button className={`SocialProfile__NavLink ${invoiceType === "admin" ? "active" : ""} ${isAuthenticated && user.role == "Admin" ? "w-1/3" : "w-1/2"}`}
                                    onClick={() => setInvoiceType("admin")}
                                >Admin view all Invoices</button>
                            )}

                        </div>

                    </div>
                    <div className="w-full bg-white shadow rounded-lg 800px:px-10 my-4 p-4 grow ">
                        <div className="mb-4">
                            <FormControl variant="outlined" style={{ minWidth: 200 }}>
                                <InputLabel>Status</InputLabel>
                                <Select
                                    value={statusFilter}
                                    onChange={(e) => setStatusFilter(e.target.value)}
                                    label="Status"
                                >
                                    <MenuItem value="All">
                                        <em>All</em>
                                    </MenuItem>
                                    <MenuItem value="Draft">Draft</MenuItem>
                                    <MenuItem value="Finalized">Finalized</MenuItem>
                                    <MenuItem value="Canceled">Canceled</MenuItem>
                                    <MenuItem value="In Progress">In Progress</MenuItem>
                                    <MenuItem value="Disputed">Disputed</MenuItem>
                                    <MenuItem value="Completed (pending payout)">Completed (pending payout)</MenuItem>
                                    <MenuItem value="Completed (paid out)">Completed (paid out)</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div style={{ transform: "rotateZ(0deg)" }}>
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                pageSize={5}
                                rowHeight={60}
                                disableSelectionOnClick
                                localeText={{
                                    noRowsLabel: isLoading
                                        ? "Loading... please wait"
                                        : "Nothing yet!",
                                }}
                                autoHeight
                                disableRowSelectionOnClick
                            />
                        </div>
                        <div className="flex justify-center px-4 pt-4">
                        {user?.stripeChargesEnabled ?
                            <Link
                                to={`/manage-invoices/new`}
                                state={{ from: "ManageInvoices" }}
                                className="w-full 800px:max-w-[50%]  h-[40px] flex justify-center items-center py-2 px-4 border border-transparent text-xl rounded-full text-white bg-[#1B0035]"
                            >
                                Create a new invoice
                            </Link>
                            : <Link to={`/profile/partnerProgramRedirect`} >Join our partner program to create your own invoices</Link>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ManageInvoices;