import React from "react";
import AdminSideBar from "../components/Admin/Layout/AdminSideBar";

const AdminDashboardPage = () => {
  return (
    <div>
      <div className="w-full flex">
        <div className="flex items-start justify-between w-full">
          <div className="w-[80px] 800px:w-[330px]">
            <AdminSideBar active={0} />
          </div>
       <div className="w-full p-5">
        Welcome to the admin panel!<br />
        Please choose an option from the left menu
       </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardPage;
