import React from "react";
import CommentList from "./CommentList";
import { AiOutlineClose } from "react-icons/ai";
import { useState, useEffect } from "react";
import axios from "axios";
import { server } from "../../server";
import Cookies from "js-cookie";
import CompactProfile from '../Social/CompactProfile';

const LeftSidebar = ({ leftSidebarOpen, setLeftSidebarOpen }) => {
    const [suggestedUsers, setSuggestedUsers] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const headers = {
                    Authorization: `Bearer ${Cookies.get('token')}`,
                };
                const suggestedUsers = await axios.get(`${server}/user/suggest-users`, { headers });
                setSuggestedUsers(suggestedUsers.data.suggested);
            } catch (err) {
                console.log("error", err);
            }
        };
        fetchData();
    }, []);
    if (!leftSidebarOpen) {
        return <></>;
    }

    return (
        <div className="space-y-2 mt-6 bg-white  p-2  w-full  rounded-sm flex flex-col " style={{ zIndex: "3" }} >
            <AiOutlineClose color="#1B0035" className="cursor-pointer absolute" size="20" onClick={() => { setLeftSidebarOpen(false) }} />
            <h2 className="font-bold text-center border-b-[1px] border-solid pb-[8px]">Who to follow</h2>
            {suggestedUsers.map((user) => (
                    <CompactProfile key={user._id} userObject={user} showFollowButton={false} />
            ))}
        </div>
    );
};

export default LeftSidebar;