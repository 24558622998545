import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Cookies from 'js-cookie';
import axios from "axios";
import { server } from "../../server";
import { toast } from 'react-toastify';
import { AiOutlineDelete } from 'react-icons/ai';
import {RxCross1} from 'react-icons/rx';

function DeleteModal({ post, deletePost, postType="Post" }) {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        setOpen(false);
        try {
            const token = Cookies.get('token');

            // Set up the authorization header with the token
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            axios.delete(`${server}/posts/${post._id}`, config).then((res) => {
                deletePost([post._id, res.data.deletedCount]);
        
            });
               toast.success(postType+" deleted successfully");
               //window.location.reload();
            
        } catch (err) {
            console.log("error", err);
        }
    };

    return (
        <div className='absolute top-2 right-2'>
            <RxCross1 className=" cursor-pointer " size={20} color='#1B0035' onClick={handleClickOpen}/>
            
            
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete "+postType}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <div>
                            Are you sure you want to delete this {postType}?
                        </div>
                        <br />
                        <div>
                            "{post.description}"
                        </div>
                        <br />
                        <div>
                            This cannot be undone.
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default DeleteModal;