import React from "react";
import ProfileSideBar from "../../components/Profile/ProfileSidebar";
import { useSelector } from "react-redux";
import LoginPage from "../Login";
import CompactProfile from "../../components/Social/CompactProfile";

const ProfilePage = () => {
  const { user, isAuthenticated } = useSelector((state) => state.user);

  if (!isAuthenticated) {
    return <LoginPage />
  }
  return (
    <div>
      <div className={`w-[90%] mx-auto 800px:flex py-10`}>
        <ProfileSideBar active={"blocked-users"} />
        <div className="w-full bg-white py-4 800px:mx-4 shadow rounded-lg px-10 my-4 800px:my-0">
          <div className="800px:w-[50%] mx-auto">
            <h1 className="block text-[25px] text-center font-[600] text-[#000000ba] pb-2">
              Blocked Users
            </h1>
            <p >
              Blocked users will not appear in search results, chat, forum posts, or comments.
            </p>
            {!user || !user.blocked || user.blocked.length === 0 ? (
              <div className='text-center text-l pt-3'>
                You have not blocked any users. To block a user visit their profile and click on the "Block" button below their avatar.
              </div>) : (<>{user.blocked.map((userId) => {
                return (
                  <div className="p-2 forum-post" key={userId}>
                    <CompactProfile userId={userId} showFollowButton={false} />
                  </div>
                )
              })}
                <p>
                  Users can be unblocked by visiting their profile and clicking the "Unblock" button below their avatar.
                </p>
              </>
            )
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
