import { Link } from "react-router-dom";

const AuthHeader = () => {
    return (
        <div className="text-center">
            <Link to="/" className="inline-block mt-8">
                <img
                    src={require("../../images/new-logo.png")}
                    alt="Logo"
                    className=" max-h-[60px] 800px:max-h-[100px]"
                />
            </Link>
        </div>
    );
};

export default AuthHeader;
