import { React, useState, useEffect } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import styles from "../../styles/styles";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from "react-redux";
import {
  loadUser
} from "../../redux/actions/user";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const {reset_token} = useParams();
  
  // Redirect if user exists
  useEffect(() => {
    if (user) {
      navigate('/'); // redirect already logged in users back home
    }
  }, [user]); // run this whenever user variable changes
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Retrieve the token from the cookies
    const token = Cookies.get('token');
    // Set up the authorization header with the token
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    };
    await axios
      .post(
        `${server}/user/set-forgot-password`,
        {
          password:password,
          token:reset_token
        },
        config
      )
      .then((res) => {
        toast.success("Your Password has been reset successfully");
        navigate('/login');
      })
      .catch((err) => {
        toast.error("This link has expired. Please try generating a new reset link.");
      });
  };

  return (
<div className=" flex flex-col justify-center">
      <div className="mt-8 mx-auto 800px:w-[35%] w-[90%]">
        <div className="bg-white py-8 px-4 shadow rounded-lg px-10">
          <h2 className="pb-4 text-center text-3xl font-extrabold text-gray-900">
            Choose a New Password
          </h2>
          <form className="space-y-6" onSubmit={handleSubmit}>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                New Password
              </label>
              <div className="mt-1 relative">
                <input
                  type={visible ? "text" : "password"}
                  name="password"
                  autoComplete="current-password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-[#1B0035]"
                />
                {visible ? (
                  <AiOutlineEye
                    className="absolute right-2 top-2 cursor-pointer"
                    size={25}
                    onClick={() => setVisible(false)}
                    color="#1B0035"
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    className="absolute right-2 top-2 cursor-pointer"
                    size={25}
                    onClick={() => setVisible(true)}
                    color="#1B0035"
                  />
                )}
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="w-full h-[40px] flex justify-center items-center py-2 px-4 border border-transparent text-xl rounded-full text-white bg-[#1B0035]"
              >
                Save New Password
              </button>
            </div>
          </form>
          <div className="text-sm w-full text-center pt-4">
            <Link to="/forgot-password"
              className="font-medium text-[#1B0035]"
            >
              Generate a new reset link
            </Link>
          </div>
        </div>

      </div>
    </div>
  );
};

export default ForgotPassword;
