import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { IoMdClose } from 'react-icons/io';
import { FaXTwitter } from 'react-icons/fa6';
import { toast } from 'react-toastify';
import { FaRegShareSquare } from "react-icons/fa";
import Cookies from 'js-cookie';
import axios from 'axios';
import { server } from "../../server";

function AdminUnpublishService({ offering }) {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const adminUnpublishService = () => {
        const token = Cookies.get('token');
        axios.put(`${server}/services/admin/unpublish/${offering?._id}`, {}, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                toast.success(res.data.message);
                setOpen(false);
                //refresh the page
                window.location.reload();
            })
            .catch((error) => {
                toast.error(error.response.data.message);
            });
        setOpen(false);
    };

    return (
        <div className=''>
            <button onClick={handleClickOpen}
                className="mt-4 w-full h-[40px] flex justify-center items-center py-2 px-4 border border-transparent text-xl rounded-full text-white bg-[#1B0035]"

            >Admin unpublish post</button>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{ style: { minWidth: '300px', overflowX: "hidden" } }}
            >
                <DialogTitle id="alert-dialog-title" style={{ paddingRight: '48px' }}>
                    Admin unpublish operation
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        style={{
                            position: 'absolute',
                            right: '-5px',
                            top: '-5px',
                            color: '#1B0035',
                        }}
                    >
                        <IoMdClose />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <p>
                            "{offering?.title}"
                        </p>
                        <br />

                        <button
                            onClick={adminUnpublishService}
                            className="mt-4 w-full h-[40px] flex justify-center items-center py-2 px-4 border border-transparent text-xl rounded-full text-white bg-[#1B0035]"
                        >
                            UNPUBLISH THIS SERVICE
                        </button>


                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default AdminUnpublishService;