import React from "react";
import LoginPage from "../Login";
import Footer from "../../components/Layout/Footer";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { Button } from "@material-ui/core";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { server } from "../../server";
import Cookies from 'js-cookie';
import { MdTitle } from "react-icons/md";
import { TextareaAutosize } from "@material-ui/core";
import { FaArrowLeft } from "react-icons/fa6";
import TagDeletionModal from "../../components/Admin/TagDeletionModal";
const AdminCRUDTags = ({ type }) => {
    const { user, isAuthenticated } = useSelector((state) => state.user);
    const { tagId } = useParams();
    const [name, setName] = useState("");
    const navigate = useNavigate();
    const [description, setDescription] = useState("");
    useEffect(() => {

        if (tagId !== "new") {
            // Retrieve the token from the cookies
            const token = Cookies.get('token');

            // Set up the authorization header with the token
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            axios.get(`${server}/services/tag/${tagId}`, config).then((res) => {
                setName(res.data.tag.name);
                setDescription(res.data.tag.description);
            })
        }
    }, []);

    const handleSubmit = async (e) => {

        e.preventDefault();
        try {
            if (!name) return toast.error("A name is required!");
            const token = Cookies.get('token');
            // Set up the authorization header with the token
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const submit = await axios.put(`${server}/${type}/tags/${tagId}`, {
                name: name,
                description: description
            }, config);
            toast.success(`Tag ${tagId == "new" ? "created" : "updated"} successfully!`);
            navigate(`/admin/${type}/tags`);
        } catch (err) {
            console.log(err);
        }
    };

    return (

        <>
            <div className="py-6 grid grid-cols-1 md:grid-cols-4">
                <div className="px-6">
                </div>
                <div className="col-span-2 border-t-slate-800 800px:px-6 ">
                    <div className="w-full 800px:mx-8 p-2  mt-10 bg-white rounded-lg">

                        <div className="flex justify-between mx-2 mt-2 mb-5 ">
                            <Link to={`/admin/${type}/tags`} className="text-[#1B0035] flex items-center"><FaArrowLeft color="#1B0035" className="pr-1" /> Back to {type.charAt(0).toUpperCase() + type.slice(1)} tags</Link>
                            {(tagId !== "new") && (
                            <TagDeletionModal name={name} tagId={tagId} type={type} />
                            )}
                        </div>
                        {tagId === "new" ? (
                            <p className="text-center text-2xl mb-5">Creating a new {type.charAt(0).toUpperCase() + type.slice(1)} tag</p>
                        ) : (

                            <p className="text-center text-2xl mb-5">Editing an existing {type.charAt(0).toUpperCase() + type.slice(1)} tag</p>
                        )}
                        <div className="flex justify-between items-center">


                        </div>

                        <div>


                            <br />
                            <label className="pb-2">
                                Name <span className="text-red-500">*</span>
                            </label>
                            <input
                                type="text"
                                name="name"
                                value={name}
                                className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                onChange={(e) => setName(e.target.value)}
                                placeholder="name..."
                            />
                            <br />
                            <label className="pb-2">
                                Description
                            </label>
                            <TextareaAutosize
                                minRows={3}
                                type="text"
                                name="description"
                                value={description}
                                className="mt-2 appearance-none block w-full p-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                onChange={(e) => setDescription(e.target.value)}

                            />
                        </div>

                        <button
                            id="profile-update-submit-button"
                            onClick={handleSubmit}
                            className="mt-5 w-full 800px:max-w-[50%] mx-auto h-[40px] flex justify-center items-center py-2 px-4 border border-transparent text-xl rounded-full text-white bg-[#1B0035]"
                        >

                            {tagId === "new" ? (
                                <>Create Tag</>
                            ) : (
                                <>Update Tag</>
                            )}
                        </button>


                    </div>

                </div>

            </div>
        </>
    );
};
export default AdminCRUDTags;
