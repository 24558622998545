import React, { useState, useEffect } from "react";
import axios from "axios";
import { server } from "../server";
import { Button } from "@material-ui/core";
import CompactProfile from "../components/Social/CompactProfile";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import LoginPage from "./Login";
import { FaChevronDown } from 'react-icons/fa';
import { Drawer, Box, List } from '@material-ui/core';
import { FaMagnifyingGlass } from "react-icons/fa6";
import { AiOutlineTeam } from "react-icons/ai";
import { Fragment } from "react";
import ServiceOfferingCard from "../components/Services/ServiceOfferingCard";
import Cookies from 'js-cookie';

const Search = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchResults, setSearchResults] = useState({});
    const { isAuthenticated } = useSelector((state) => state.user);
    const { user } = useSelector((state) => state.user);
    const [searchCategory, setSearchCategory] = useState("everything");
    const [mobileMenu, setMobileMenu] = useState(false);

    useEffect(() => {
        // Retrieve the token from the cookies
        const token = Cookies.get('token');

        // Set up the authorization header with the token
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        };
        axios.get(`${server}/search/${searchCategory}/${searchParams.get("query") ?? ""}`, config).then((res) => {
            setSearchResults(res.data);
        });
    }, [searchCategory, setSearchParams]);
    // if (!isAuthenticated) {
    //     return <LoginPage />
    // }
    const menuItems = [
        { icon: FaMagnifyingGlass, text: "Everything" },
        { icon: AiOutlineTeam, text: "Users" },
        { icon: AiOutlineTeam, text: "Services" },
    ];

    const SearchCategoryButtons = ({ searchCategory, setSearchCategory }) => {
        const baseStyle = "h-[40px]flex items-center py-2 px-4 border text-xl rounded-full";
        const activeStyle = "bg-[#1B0035] text-white border-transparent";
        const inactiveStyle = "border-[#1B0035] text-[#1B0035] bg-white";

        return (
            <div className="flex-row justify-center pt-3 800px:flex hidden">
                {menuItems.map((category, index) => (
                    <button
                        key={`${category.text}-desktop`}
                        className={`${baseStyle} ${index !== 0 ? 'ml-4 ml-1' : ''} ${searchCategory === category.text.toLowerCase() ? activeStyle : inactiveStyle}`}
                        onClick={() => { setSearchCategory(category.text.toLowerCase()) }}
                    >
                        {category.text.charAt(0).toUpperCase() + category.text.slice(1)}
                    </button>
                ))}
            </div>
        );
    };

    const toggleDrawer =
        (open) =>
            (event) => {
                if (
                    event.type === 'keydown' &&
                    ((event).key === 'Tab' ||
                        (event).key === 'Shift')
                ) {
                    return;
                }

                setMobileMenu((oldState) => !oldState);
            };

    const activeItem = menuItems.find(item => item.text.toLowerCase() === searchCategory);
    const renderMenuItem = (item) => (
        <div className="flex items-center mb-8" onClick={() => setSearchCategory(item.text.toLowerCase())}>
            <item.icon size={20} color={activeItem === item.text ? "#1B0035" : ""} />
            <span className={`pl-3 ${activeItem === item.text ? "text-[#1B0035]" : ""}`}>{item.text}</span>
        </div>
    );
    return (
        <>
            <div className="max-w-[90%] mx-auto">
                <div className="py-6 max-w-[700px] mx-auto min-h-[100svh]">
                    <div className=" p-4 bg-white shadow rounded">
                        <input
                            placeholder="Search ConnectSiimply"
                            type="text"
                            value={searchParams.get("query")}
                            onChange={(e) => setSearchParams({ query: e.target.value }, { replace: true })}
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-[#1B0035]"
                        />
                        <SearchCategoryButtons searchCategory={searchCategory} setSearchCategory={setSearchCategory} />

                        <div className="800px:hidden">
                            <Button onClick={toggleDrawer(true)} className="!bg-white w-full shadow !justify-around items-center !mt-2">
                                <span className="flex items-center">
                                    <activeItem.icon size={20} />
                                    <span className="pl-3 ">{activeItem?.text}</span>
                                    <FaChevronDown className="ml-3 " />
                                </span>

                            </Button>
                            <Drawer open={mobileMenu} onClose={toggleDrawer(false)} anchor="top">
                                <Box role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
                                    <List>
                                        <div className="bg-white p-4 pt-4">
                                            {menuItems.map((item, index) => {
                                                return (<Fragment key={item.text}>
                                                    {renderMenuItem(item)}
                                                </Fragment>
                                                );
                                            })}
                                        </div>
                                    </List>
                                </Box>
                            </Drawer>
                        </div>

                    </div>
                    {searchResults.users && ((searchResults.users.length > 0 && searchCategory === "everything") || searchCategory === "users") &&
                        <div className="w-full bg-white shadow rounded-lg p-4 mt-4">
                            <h1 className="block text-[25px] text-center font-[600] text-[#000000ba] pb-2">
                                Users
                            </h1>
                            {searchResults.users.length === 0 ? (
                                <div className="text-center text-lg">
                                    No users found.
                                </div>) :

                                searchResults.users.map((userObject) => {
                                    if (isAuthenticated && user.blocked?.includes(userObject._id)) return null;
                                    return (
                                        <div className="p-2 forum-post" key={userObject._id}>
                                            <CompactProfile userId={userObject._id} userObject={userObject} />
                                        </div>
                                    );
                                })
                            }
                        </div>
                    }
                    {searchResults.services &&((searchResults.services.length > 0 && searchCategory === "everything") || searchCategory === "services") &&
                        <div className={`mt-4 w-full bg-white shadow rounded-lg p-4 space-y-2 `}>
                            <h1 className="block text-[25px] text-center font-[600] text-[#000000ba] pb-2 800px:col-span-2">
                                Services
                            </h1>
                            {searchResults.services.length === 0 ? (
                                <div className="text-center text-lg 800px:col-span-2">
                                    No Services found.
                                </div>) :

                                searchResults.services.map((serviceObject) => {
                                    return (
                                            <ServiceOfferingCard offering={serviceObject} key={serviceObject._id}/>
                                    );
                                })
                            }
                        </div>
                    }
                </div>
            </div >
        </>
    );
};

export default Search;
