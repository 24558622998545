import React from 'react'
import Footer from "../components/Layout/Footer";
import { useLoaderData } from "react-router";
import { Link } from "react-router-dom";
import styles from "../styles/styles";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaSearch } from 'react-icons/fa';
import Ratings from "../components/Reviews/Ratings";
const HomePage = () => {
  const loaderData = useLoaderData();
  const { isAuthenticated } = useSelector((state) => state.user);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchQuery.trim() === "") {
      return;
    }
    navigate(`/search?query=${searchQuery}`);

  };
  return (
    <>
      <div
        className={`min-h-[70svh] flex`}
        style={{
          background: "#FDF8EE",
        }}
      >
        <div className={`w-11/12 mx-auto my-auto flex 800px:flex-row flex-col h-full`}>
          <div className="800px:w-1/2 flex flex-col justify-center min-h-[40svh] ">

            <div className="800px:max-w-[75%] mx-auto">
              <div className="text-[45px] leading-[1.0] 800px:text-[60px] font-[600] ">
                Freelance Is The<br /><span className="text-[#4e2c5e] ">Future</span></div>
              <div className=" text-[15px] 800px:text-[20px] font-[300] mt-4 text-gray-400">
                Begin your journey with ConnectSiimply. <br />Explore the ConnectSiimply platform to discover the skills you're looking for
              </div>
              <form onSubmit={handleSubmit} className="flex items-center mt-4">
                <div className="relative w-full">
                  <input
                    type="text"
                    name="search"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Search ConnectSiimply"
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-l-full shadow-sm placeholder-gray-400 focus:outline-none focus:border-[#1B0035] pl-10"
                  />
                  <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-300" size={20} />
                </div>
                <button type="submit" className="px-4 py-[9px] rounded-r-full bg-[#1B0035] text-white">
                  Search
                </button>
              </form>
            </div>
          </div>
          <div className="800px:w-1/2  my-auto">
            {/* will contain the hero image */}
            <img
              src={require("../images/hero-image.png")}
              alt="Hero image"
              className="w-[80%] mx-auto"
            />
          </div>
        </div>

      </div>
      <div className="w-full h-[10svh] bg-[#FDF8EE]"></div>
      <div
        className=" 800px:mt-[-10svh] mt-[-5svh] min-h-[20svh]  p-5 bg-[#4D2C5E] w-[85%] 800px:w-[70%] flex items-center  mx-auto rounded-lg text-slate-200">

        <div className="flex 1100px:flex-row flex-1 flex-col items-start my-auto 1100px:space-y-0 space-y-5">
          <div className="flex flex-row items-center ">
            <img
              src={require("../images/Create.png")}
              alt=""
              className="h-[60px]"
            />
            <div className="">
              <div className="text-[25px] font-[600] ">Create</div>
              <div className="text-[15px] ">Crafting extraordinary and innovative projects tailored to exceed client expectations.</div>
            </div>
          </div>
          <div className="flex flex-row items-center">
            <img
              src={require("../images/Helping Hand.png")}
              alt=""
              className="h-[60px]"
            />
            <div className="">
              <div className="text-[25px] font-[600]">Collaborate</div>
              <div className="text-[15px]">Team up with other freelancers and pros to produce top-notch work for clients.</div>
            </div>
          </div>
          <div className="flex flex-row items-center ">
            <img
              src={require("../images/Heart With Mouse.png")}
              alt=""
              className="h-[60px]"
            />
            <div className="">
              <div className="text-[25px] font-[600] ">Explore</div>
              <div className="text-[15px] ">Engage, follow, and connect with fellow freelancers to build meaningful relationships.</div>
            </div>
          </div>
        </div>

      </div>
      <div
        className={`min-h-[60svh] mx-auto flex items-center justify-center`}
      >
        <div className={`flex 800px:flex-row flex-col h-full`}>


          <div className="800px:w-[40%] my-auto">
            {/* will contain the hero image */}
            <img
              src={require("../images/happy-man.png")}
              alt=""
              className="w-[40%] py-2 mx-auto"
            />
          </div>

          <div className="800px:w-1/2 relative flex flex-col justify-center min-h-[30svh] ">
            <img
              src={require("../images/Group.png")}
              alt=""
              className="w-[50px] absolute 800px:top-2 top-0 left-3"
            />
            <img
              src={require("../images/Group (1).png")}
              alt=""
              className="w-[50px] absolute bottom-5 right-3"
            />

            <div className=" mx-auto text-center py-[65px]">
              <div className="text-[32px] leading-[1.0] 800px:text-[50px] font-[300] ">
                We’ve Got What You Want </div>
              <div className=" text-[15px] 800px:text-[20px] font-[300] my-4 text-gray-400">
                "Discover your ideal freelance match. Seamless perfection, every time."
              </div>
              {!isAuthenticated &&

                <Link to={'/sign-up'} className="px-4 py-2 rounded-full bg-[#1B0035] text-white text-xl">
                  Start the adventure

                </Link>}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`min-h-[60svh] flex w-full items-center`}
        style={{
          background: "#FDF8EE",
        }}
      >

        <div className={`800px:w-8/12 w-11/12 mx-auto flex 800px:flex-row flex-col h-full`}>

          <div className="800px:w-1/2  my-auto">
            {/* will contain the hero image */}
            <img
              src={require("../images/Web page design  copy.png")}
              alt="Hero image"
              className="w-[80%] mx-auto"
            />
          </div>
          <div className="800px:w-1/2 flex flex-col justify-center min-h-[40svh] ">

            <div className="flex flex-col text-slate-700 mx-auto p-4 space-y-4">
              <div className="flex flex-row items-center ">
                <img
                  src={require("../images/Sign Up.png")}
                  alt=""
                  className="h-[60px]"
                />
                <div className="">
                  <div className="text-[35px] font-[400] ">Sign-up with no cost</div>
                  <div className="text-[18px]">Jump into ConnectSiimply for free</div>
                </div>
              </div>
              <div className="flex flex-row items-center ">
                <img
                  src={require("../images/Circled Play.png")}
                  alt=""
                  className="h-[60px]"
                />
                <div className="pl-4">
                  <div className="text-[35px] font-[400]">Create with us</div>
                  <div className="text-[18px]">Create and share content with your network</div>
                </div>
              </div>

              <div className="flex flex-row items-center ">
                <img
                  src={require("../images/Management.png")}
                  alt=""
                  className="h-[60px]"
                />
                <div className="pl-5">
                  <div className="text-[35px] font-[400]">Connect with</div>
                  <div className="text-[18px]">Explore, connect, and hire freelancers effortlessly.</div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div
        className={` flex`}
      >
        <div className={`800px:mt-5 1100px:w-8/12 mx-auto flex 800px:flex-row flex-col h-full`}>
          <div className="800px:w-1/2 flex flex-col justify-center bg-[#4D2C5E] ">

            <div className="800px:max-w-[85%] mx-auto text-center 800px:text-left p-4 800px:p-1 ">
              <div className="text-[45px] leading-[1.0] 800px:text-[50px] font-[500] text-[#B495FB] ">
                Why ConnectSiimply?</div>
              <div className=" text-[15px] 800px:text-[20px] font-[300] mt-4 text-white">
                ConnectSiimply integrates content creation and social media to help freelancers achieve success in their careers.
              </div>

            </div>
          </div>
          <div className="800px:w-1/2  ">
            {/* will contain the hero image */}
            <img
              src={require("../images/women.jpg")}
              className=" mx-auto object-cover h-full"
            />
          </div>
        </div>

      </div>
      <div
        className={` flex`}
      >
        <div className={`800px:w-8/12 w-[95%] mx-auto flex flex-col h-full bg-white shadow rounded-lg my-5 `}>
          <h1 className="text-4xl text-center w-full font-[400] text-[#4D2C5E] py-2">Explore our members talents</h1>
          {/* list of tags and links in grid form */}
          <div className="grid 800px:grid-cols-4 grid-cols-2 gap-4 p-4">
            {loaderData?.tags.map((tag) => (
              <Link key={tag._id} to={`/services/${encodeURIComponent(tag.name.replaceAll(" ","_"))}`} className="flex flex-col items-center w-full bg-white rounded-lg p-2 cursor-pointer relative shadow-sm border  border-[#4e2c5e] ">
                <h3 className="text-center w-full font-[300]">{tag.name}</h3>
                <ul className="self-start font-[100]">
                  <li>{tag.uniqueUserCount} talented creators </li>
                  <li>{tag.adCount} postings</li>
                </ul>
                <Ratings rating={tag.averageRating} />
              </Link>
            ))}

          </div>
        </div>
      </div>
    </>
  )
}

export default HomePage