import axios from "axios";
import React, { useRef, useState } from "react";
import formatDistance from "date-fns/formatDistance";

import { useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { AiOutlineHeart } from "react-icons/ai";
import { AiFillHeart } from "react-icons/ai";
import { server } from "../../server";
import Cookies from 'js-cookie';
// import DeleteModal from "./DeleteModal";
// import ShareModal from "./ShareModal";
import { AiOutlineMessage } from "react-icons/ai";
import { toast } from "react-toastify";
import Button from '@material-ui/core/Button';
import { CiLocationOn } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import Markdown from 'react-markdown'
import { FaArrowLeft } from "react-icons/fa";
import Ratings from "../Reviews/Ratings";
import styles from "../../styles/styles";
import Tooltip from '@atlaskit/tooltip';
import { BiSolidBadgeCheck } from "react-icons/bi";
import ShareModal from "../Services/ShareModal";
import AdminUnpublishModal from "../Services/AdminUnpublishModal";

const ServiceOfferingDetails = ({ serviceOfferingData, setServiceId, currentUrlTags }) => {
    const { user } = useSelector((state) => state.user);
    const navigate = useNavigate();
    const location = useLocation();
    const mediaContainerRef = useRef(null);

    const goBackToServices = () => {
        // Get the previous path
        const previousPath = location.state?.from || "/";
        // Check if the previous path matches the desired path
        if (previousPath === `/services/${currentUrlTags ?? ""}` && location.state.search === "") {
            // If it does, go back
            navigate(-1);
        } else {
            // If it doesn't, navigate to the desired path
            navigate(`/services/${currentUrlTags ?? ""}`);
        }
    };
    useEffect(() => {
        if (serviceOfferingData) {
            // send a get request to ensure that viewcount is updated
            axios.put(`${server}/services/increment-viewcount/${serviceOfferingData?._id}`);
        }
    }, [serviceOfferingData?._id]);


    useEffect(() => {
        let galleryWidget = null;
        if (window && !galleryWidget && serviceOfferingData?.media.length > 0) {
            // apply transformations to our media items
            serviceOfferingData?.media.forEach(item => {
                item.transformation = { "crop": "", "background": "#fff", "fetch_format": item.mediaType === "image" ? "webp" : "mp4" };
                item.thumbnailTransformation = { "crop": "fill", "dpr": "1.0", "fetch_format": "auto", "radius": 7 };

            });
            const loadingMedia = [{ publicId: "forum_media/1x1-00000000_dznnj4", transformation: { "crop": "fill", "background": "#fff", "dpr": "1.0", "fetch_format": "webp" }, thumbnailTransformation: { "crop": "fill", "dpr": "1.0", "fetch_format": "auto", "radius": 7 } }]
            galleryWidget = window.cloudinary.galleryWidget({
                container: mediaContainerRef.current,
                cloudName: 'dljapfuhk',
                mediaAssets: serviceOfferingData?.media ?? loadingMedia,
                carouselLocation: 'bottom',
                carouselStyle: 'thumbnails',
                preLoad: ["image", "video"],
                zoom: false,
                placeholderImage: false,
                analytics: false,
                thumbnailProps: {
                    selectedBorderPosition: 'bottom',
                    selectedBorderColor: '#1B0035',
                    radius: 7
                },
                zoomProps: {
                    type: "popup",
                    steps: 0,
                },
                videoProps: {
                    controls: "all",
                    autoplay: false,
                    sound: true,
                },
                aspectRatio: "4:3",
            })
        }
        if (mediaContainerRef.current && galleryWidget && serviceOfferingData?.media.length > 0) {
            galleryWidget.render();
        }

        return () => {
            if (galleryWidget?.destroy) {
                galleryWidget.destroy();
            }
        };
    }, [serviceOfferingData]);


    return (
        <>
            <div className="bg-white 800px:rounded-lg shadow-sm border border-gray-200 relative">
                {user?.role == "Admin" && <AdminUnpublishModal offering={serviceOfferingData} />}
                {/* <div className="pb-4 800px:hidden p-3">
                    <button onClick={goBackToServices} className="text-[#1B0035] flex items-center"><FaArrowLeft color="#1B0035" className="pr-1" /> Back to all services</button>
                </div> */}
                <div ref={mediaContainerRef} style={{ minHeight: "350px", display: serviceOfferingData?.media.length == 0 ? "none" : "block" }}></div>
                <h1 className="text-3xl font-bold text-center p-1">{serviceOfferingData?.title ?? "Loading"}</h1>
                <h1 className="text-l text-center py-2 p-3">Starting From: ${serviceOfferingData?.startingPrice ?? "000"}</h1>
                <ShareModal offering={serviceOfferingData} />


            </div>
            {serviceOfferingData?.tags.length > 0 && (

                <div className="bg-white 800px:rounded-lg p-2 shadow-sm border border-gray-200">
                    <div className=" flex flex-wrap gap-y-2 justify-center">
                        {serviceOfferingData?.tags.map((tag, index) => (
                            <Tooltip key={index} content={tag.description}>
                                <span className="inline-block rounded-full px-3 py-1 text-sm  mr-2 border-[1px] border-[#500696] ">{tag.name}</span>
                            </Tooltip>
                        ))}

                    </div>
                </div>
            )}

            <div className="bg-white 800px:rounded-lg p-2 shadow-sm border border-gray-200 ">

                <Link to={`/profile/${serviceOfferingData?.account._id}/services`} className=" flex  justify-between flex-col 800px:flex-row">
                    <span className="flex items-center">
                        <div className="relative">
                            <img
                                src={`https://res.cloudinary.com/dljapfuhk/image/upload/ar_1.0,c_thumb,g_auto,f_auto/r_max/v1703309315/${serviceOfferingData?.account.avatar?.public_id}.jpg`}
                                className="w-[75px] min-w-[75px] rounded-full mr-[10px]" alt=""
                            />

                            {serviceOfferingData?.account?.online && (<div className="absolute bg-green-500 w-4 h-4 rounded-full mr-3 top-0 right-0"></div>)}
                        </div>
                        <div>
                            <div className="flex items-center text-left">
                                <h5 className={`${styles.shop_name}`}>{serviceOfferingData?.account.name ?? "Loading..."}</h5>
                                {serviceOfferingData?.account.isPremium && (<BiSolidBadgeCheck className="" size="20" color="#1B0035" />)}
                            </div>
                            {serviceOfferingData?.account.title ?
                                (<div className="text-sm  text-[#1B0035] font-medium my-[-5px] text-left">
                                    {serviceOfferingData?.account.title || ' '}
                                </div>) : ' '}
                            <div className="text-sm flex items-center">

                                <CiLocationOn /> {serviceOfferingData?.account.publicLocation ?? "Planet Earth"}
                            </div>



                        </div>

                    </span>

                    <div className="text-sm flex items-center mx-auto">

                        Languages spoken:  {serviceOfferingData?.languagesSpoken}<br />
                        Open to collaboration: {serviceOfferingData?.openToCollaboration ? "yes" : "No"}

                    </div>
                </Link>
                <Button
                    variant="contained"
                    color="primary"
                    className="!mx-auto !block !mt-4 !rounded-full"
                    onClick={() => navigate(`/chat?with=${serviceOfferingData?.account?._id}`)}>
                    Message to start project
                </Button>
            </div >

            {
                serviceOfferingData?.description.length > 0 && (
                    <div className="bg-white 800px:rounded-lg p-2 shadow-sm border border-gray-200">

                        <h2 className="text-center text-xl font-bold py-3">About the Gig</h2>
                        <Markdown className="prose text-black pb-2">{serviceOfferingData?.description}</Markdown>
                    </div>
                )
            }
        </>
    );
};

export default ServiceOfferingDetails;
