import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { IoMdClose } from 'react-icons/io'; // Import the close icon from react-icons
import { FaXTwitter } from 'react-icons/fa6';
import { toast } from 'react-toastify';
import { BiRepost } from "react-icons/bi";
import axios from 'axios';
import Cookies from 'js-cookie';
import { server } from "../../server";
import UploadWidget from '../UploadWidget';
import { useSelector } from 'react-redux';
import { BiSolidBadgeCheck } from "react-icons/bi";
import { IoMdCreate } from "react-icons/io";

function PostModal({ prependPost }) {
    const [open, setOpen] = useState(false);
    const { user, isAuthenticated } = useSelector((state) => state.user);
    const [postText, setPostText] = useState("");

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!user) return toast.error("Please login to post!");
        if (!postText) return toast.error("Please write something!");
        try {
            // Find all elements with the class 'cloudinary-thumbnail'
            const elements = document.querySelectorAll('.cloudinary-thumbnail');

            // Map over the elements and extract the public_id from the data-cloudinary attribute
            const media = Array.from(elements).map((el) => {
                const data = JSON.parse(el.getAttribute('data-cloudinary'));
                return { publicId: data.public_id, mediaType: data.resource_type };
            });
            const token = Cookies.get('token');
            // Set up the authorization header with the token
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };

            const submitPost = await axios.post(`${server}/posts`, {
                // userId: user._id,
                description: postText,
                media: media,
                profile: "user",
            }, config).then((res) => {
                prependPost(res.data.post);
            });
            //   setReloadTimeline(Date.now());
            setPostText("");
            setOpen(false);
            // Select all elements that match our thumbnail selector
            const cloudinaryThumbnails = document.querySelectorAll('.cloudinary-thumbnails');

            // Loop through the NodeList and remove each element from the DOM
            cloudinaryThumbnails.forEach(thumbnails => {
                thumbnails.parentNode.removeChild(thumbnails);
            });
            toast.success("Post submitted successfully!");
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div >
            <IoMdCreate className=" cursor-pointer fixed 800px:bottom-20 800px:left-20 bottom-4 left-4 bg-white rounded-full w-[55px] h-[55px] p-2" style={{zIndex:"2"}} color="#1B0035" onClick={handleClickOpen} />

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    style: { overflowX: "hidden" },
                    className: 'w-full'
                }}
            >
                <DialogTitle id="alert-dialog-title" style={{ paddingRight: '48px' }}>
                    Create a new post
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        style={{
                            position: 'absolute',
                            right: '-5px',
                            top: '-5px',
                            color: '#1B0035', // Optional: style the color of the close button
                        }}
                    >
                        <IoMdClose />
                    </IconButton>
                </DialogTitle>
                <DialogContent className='px-2 !pb-[16px]'>
                    <div className="flex forum-post-top px-2 800px:px-0 ">
                        <img
                            src={`https://res.cloudinary.com/dljapfuhk/image/upload/ar_1.0,c_thumb,g_auto,f_auto/r_max/v1703309315/${user.avatar?.public_id}.jpg`}
                            className="w-[45px] min-w-[45px] rounded-full mr-[10px]" alt="" />

                        <div className={`flex flex-col ${user?.title ? "self-start" : "items-center"}`}>
                            <div className="flex items-center">
                                <h1 className="font-bold">{user?.name}</h1>
                                {user?.premiumExpirationDate > new Date().getTime() / 1000 && (<BiSolidBadgeCheck className="ml-1" size="20" color="#1B0035" />)}
                            </div>
                            {user?.title ?
                                (<div className="text-sm  text-[#1B0035] font-medium my-[-5px]">
                                    {user?.title || ' '}
                                </div>) : ' '}
                        </div>

                    </div>
                    <form className="" id="mainPostForum">
                        <textarea
                            value={postText}
                            onChange={(e) => setPostText(e.target.value)}
                            type="text"
                            placeholder="Share your thoughts!"
                            maxLength={500}
                            className="bg-slate-200 rounded-lg w-full p-2 mb-2"
                        ></textarea>
                        <UploadWidget />
                        <button
                            onClick={handleSubmit}
                            className="float-right h-[40px] flex justify-center items-center py-2 px-4 border border-transparent text-xl rounded-full text-white bg-[#1B0035]"
                        >
                            Post
                        </button>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default PostModal;