import { useEffect } from "react";
import ServiceOfferingCard from "./ServiceOfferingCard";
import axios from "axios";
import { server } from "../../server";
import { useState } from "react";
import { useOutletContext } from "react-router";
import { useLoaderData } from "react-router-dom";
import { useNavigation } from "react-router-dom";

const ProfileServiceListOfCards = () => {
    const serviceOfferings = useLoaderData().serviceOfferings;

    if (serviceOfferings?.length == 0) {
        return (
            <div className="min-h-[50vh]">
                <div className="text-center bg-white rounded-full py-4 ">
                    User has not yet posted a service.
                </div>
            </div>
        )
    }
    return (
        <div className="space-y-2">
            {serviceOfferings.map((offering, index) => <ServiceOfferingCard offering={offering} key={offering._id} />)}
        </div>
    )
};
export default ProfileServiceListOfCards;