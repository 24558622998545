import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Cookies from 'js-cookie';
import axios from "axios";
import { server } from "../../server";
import { toast } from 'react-toastify';
import { AiOutlineDelete } from 'react-icons/ai';
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';

function ReviewDeletionModal({ type, offeringId, review, reviewId, setReviewId, setShowReviewEditor, setAverageRating, setReviewText, setRating }) {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        setOpen(false);
        setShowReviewEditor(true);
        setRating(1);
        setReviewText("");

        try {
            const token = Cookies.get('token');

            // Set up the authorization header with the token
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            axios.delete(`${server}/${type}/review/${reviewId}?id=${offeringId}`, config).then((res) => {
                setAverageRating(res.data.averageRating);
                setReviewId(false);
                toast.success("Review deleted successfully");
            });
            //    deletePost(post._id);
               //navigate("/admin/services/categories/manage");
               //reloadParentPage();

               //window.location.reload();
            //    window.location.href="/admin/services/categories/manage";
            
        } catch (err) {
            console.log("error", err);
        }
    };

    return (
        <div>
            <Button variant="contained"
                                color="primary" onClick={handleClickOpen}
                                className='!rounded-full'
                                >
                                <AiOutlineDelete className="mr-2 cursor-pointer" size="20" /> DELETE
                            </Button>
            
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete Review"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <p>
                            Are you sure you want to delete this review?
                        </p>
                        <br />
                        <p>
                            "{review}"
                        </p>
                        <br />
                        <p>
                            This cannot be undone.
                        </p>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleDelete} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ReviewDeletionModal;