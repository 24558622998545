import React, { useState, useEffect, useCallback, memo } from "react";
import Header from "../../components/Layout/Header";
import RightSidebar from "../../components/Social/RightSideBar";
// import LoginPage from "../Login";
import Footer from "../../components/Layout/Footer";
import { useSelector } from "react-redux";
import axios from "axios";
import Cookies from 'js-cookie';
import { server } from "../../server";
import { useParams } from "react-router-dom";
import Post from "../../components/Social/Post";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Comment from "../../components/Social/Comment";
import { IoSendSharp } from "react-icons/io5";
import { AiOutlineClose } from "react-icons/ai";
import { sub } from "date-fns";

const CommentList = ({ postId, subList = false, timelineLoadDate = null, posts, setPosts }) => {
    const navigate = useNavigate();
    const { user, isAuthenticated } = useSelector((state) => state.user);
    //   const { id } = useParams();
    const [post, setPost] = useState(null);
    const [postText, setPostText] = useState("");
    const [reloadTimeline, setReloadTimeline] = useState(null);
    if (timelineLoadDate && timelineLoadDate !== reloadTimeline) {
        setReloadTimeline(timelineLoadDate);
    }
    const [collapsed, setCollapsed] = useState(subList);
    const { id } = useParams();
    const [replyingTo, setReplyingTo] = useState(null);

    useEffect(() => {
        setReplyingTo(null);
        const fetchData = async () => {
            try {
                const post = await axios.get(`${server}/posts/individual/${postId[0]}`);
                setPost(post.data);
                // if (!post.data.parentPostId) {
                //     setReplyForm((currentReplyForm) => [...currentReplyForm, post.data._id]);
                // }

            } catch (err) {
                console.log("error", err);
            }
        };
        fetchData();
    }, [postId[0], reloadTimeline, timelineLoadDate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!user) return toast.error("Please login to comment!");
        if (!postText) return toast.error("Please write something!");
        try {
            const token = Cookies.get('token');
            // Set up the authorization header with the token
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            };
            const submitPost = await axios.post(`${server}/posts`, {
                description: postText,
                profile: "user",
                parentPostId: replyingTo ? replyingTo[0] : post?._id,
            }, config);

            setReloadTimeline(Date.now());
            setPostText("");
            setReplyingTo(null);
            setPosts((prevPosts) => {
                return prevPosts.map((p) => {
                    if (p._id === post?._id) {
                        return {
                            ...p,
                            commentCount: p.commentCount + 1
                        };
                    } else {
                        return p;
                    }
                });
            });



            toast.success("Comment submitted successfully!");
        } catch (err) {
            console.log(err);
        }
    };
    const deleteComment = useCallback((deletedData) => {
        setPost((post) => ({
            ...post,
            comments: post.comments?.filter(comment => comment._id !== deletedData[0])
        }));
        setPosts((prevPosts) => {
            return prevPosts.map((p) => {
                if (p._id === post?._id || subList && p._id === postId[2]) {
                    return {
                        ...p,
                        commentCount: p.commentCount - deletedData[1]
                    };
                } else {
                    return p;
                }
            });
        });

    }, [post?.comments]);

    if (collapsed && post?.comments.length > 2) {
        return (
            <div className="flex justify-center">
                <ButtonGroup variant="text" color="secondary" aria-label="contained Tetriary button group">
                    <Button onClick={() => setCollapsed(false)}>View Replies ({post?.comments.length})</Button>
                </ButtonGroup>
            </div>
        )
    }
    return (
        <>

            <div className={`overflow-y-auto flex-grow ${subList && "ml-6"}`}>
                {post?.comments && post?.comments.length > 0 ? (
                    <div className="">
                        {post.comments.map((comment) => {
                            return (
                                <div className="forum-post" key={comment._id}>
                                    <Comment post={comment} deletePost={deleteComment} setReplyingTo={setReplyingTo} updateCommentList={setReloadTimeline} subList={subList} mainPostUserId={postId[1]} />
                                    <CommentList postId={[comment._id, postId[1], post?._id]} subList={true} timelineLoadDate={reloadTimeline} posts={posts} setPosts={setPosts} />
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    !subList && (
                        <p className="text-center pt-2">No comments yet!</p>
                    )
                )}
            </div>

            {!subList && (<>
                {replyingTo && (
                    <p className="flex row items-center align-middle pt-2"><AiOutlineClose color="#1B0035" className="cursor-pointer" onClick={() => { setReplyingTo(null) }} /> Replying to: {replyingTo[1]} </p>
                )}
                <form className="pt-2 flex flex-row w-full" id="mainPostForum">
                    <textarea
                        value={postText}
                        onChange={(e) => setPostText(e.target.value)}
                        type="text"
                        placeholder="Share your thoughts!"
                        maxLength={280}
                        className="bg-slate-200 rounded-lg grow p-2"
                    ></textarea>
                    <button
                        onClick={handleSubmit}
                        className="pl-2"
                        style={{ float: "right" }}
                    >
                        <IoSendSharp size="30" color="#1B0035" />
                    </button>
                </form>
            </>
            )}

        </>
    );
};
export default CommentList;
