import React from "react";

const Footer = () => {
  return (
    <>
      <div className="h-[150px] footer-class"></div>
      <div className="bg-[#fff] absolute bottom-0 w-full footer-class">
        <div
          className="grid grid-cols-1 800px:grid-cols-3 
          text-sm py-8 800px:w-6/12 mx-auto align-middle px-2 text-center 800px:text-left"
        >
          <div className="col-span-2">
            <div>Created with love by the ConnectSiimply team.</div><div>©2024 ConnectSiimply All rights reserved.</div></div>

          <ul className="">
            <li><a href='mailto:contact@connectsiimply.com'>contact@connectsiimply.com</a></li>
            <li><a href='mailto:support@connectsiimply.com'>support@connectsiimply.com</a></li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Footer;
