import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { server } from "../server";

const ActivationPage = () => {
  const { activation_token } = useParams();
  const [error, setError] = useState(false);

  useEffect(() => {
    if (activation_token) {
      const sendRequest = async () => {
        await axios
          .post(`${server}/user/activation`, {
            activation_token,
          })
          .then((res) => {
            setTimeout(() => {
              window.location.href = "/login";
            }, 5000);
          })
          .catch((err) => {
            setError(true);
            setTimeout(() => {
              window.location.href = "/login";
            }, 5000);
          });
      };
      sendRequest();
    }
  }, []);

  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {error ? (
        <p>Your token is expired!<br></br>
          Please try logging in to see if your account has already been activated.<br></br>
          You will be redirected to the login page in 5 seconds</p>
      ) : (
        <>
          <p>Your account has been created successfully!<br></br>
            You will be redirected to the login page in 5 seconds</p>
        </>
      )}
    </div>
  );
};

export default ActivationPage;
