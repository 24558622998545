import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: null,
};

export const postSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    following: (state, action) => {
      if (state.user.following.includes(action.payload)) {
        state.user.following.splice(
          state.user.following.findIndex(
            (followingId) => followingId === action.payload
          )
        );
      } else {
        state.user.following.push(action.payload);
      }
    },
  },
});

export const {
  following,
} = postSlice.actions;

export default postSlice.reducer;
