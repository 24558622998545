import React, { useState, useEffect, useCallback, memo } from "react";
import { useOutletContext } from "react-router";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate, useLocation } from 'react-router-dom';
import Markdown from 'react-markdown'

const ViewPortfolio = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [portfolioData, SetPortfolioData] = useState(null); // Set the portfolio data to null initially
    const [userProfile, postId, setPostId] = useOutletContext();
    const { portfolioId } = useParams();
    // Inside your component
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {

        axios.get(`${server}/portfolio/offering/${portfolioId}`).then((res) => {
            SetPortfolioData(res.data.portfolio);
            setIsLoading(false);  // Set loading to false after data is fetched
        })
    }, [userProfile]);
    const goBackToPortfolio = () => {
        // Get the previous path
        const previousPath = location.state?.from || "/";

        // Check if the previous path matches the desired path
        if (previousPath === `/profile/${userProfile?._id}/portfolio`) {
            // If it does, go back
            navigate(-1);
        } else {
            // If it doesn't, navigate to the desired path
            navigate(`/profile/${userProfile?._id}/portfolio`);
        }
    };

    return (
        <div className="min-h-[50vh]">

            <div className="bg-white rounded-lg py-4">
                <button onClick={goBackToPortfolio} className="text-[#1B0035] mx-3 mb-5 flex items-center"><FaArrowLeft color="#1B0035" className="pr-1" /> Back to Portfolio</button>
                <h1 className="text-center text-2xl font-medium mb-5">{portfolioData?.title}</h1>
                <Markdown className="prose text-black pb-2 mx-[2%]">{portfolioData?.description}</Markdown>
                {portfolioData?.media?.map((media, index) => (
                    <img
                        key={media.publicId}
                        src={`https://res.cloudinary.com/dljapfuhk/${media?.mediaType}/upload/${media?.mediaType == "image" ? "f_webp" : ""}/v1/${media?.publicId}`}
                        alt=""
                        className="mb-5 800px:mx-[2%] 800px:w-[96%] w-full object-contain 800px:rounded-lg"
                        style={{}}
                    />
                ))}
            </div>

        </div>
    );
};

export default ViewPortfolio;
