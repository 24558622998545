import axios from "axios";
import React, { useRef, useState } from "react";
import formatDistance from "date-fns/formatDistance";

import { useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { AiFillMessage, AiOutlineHeart } from "react-icons/ai";
import { AiFillHeart } from "react-icons/ai";
import { AiOutlineLike, AiFillLike } from "react-icons/ai";
import { FaRegCommentOutlined } from "react-icons/fa";
import { server } from "../../server";
import Cookies from 'js-cookie';
import DeleteModal from "./DeleteModal";
import ShareModal from "./ShareModal";
import RepostModal from "./RepostModal";
import { AiOutlineMessage } from "react-icons/ai";
import { toast } from "react-toastify";
import Button from '@material-ui/core/Button';
import { BiSolidBadgeCheck } from "react-icons/bi";
import { BiRepost } from "react-icons/bi";

const Post = ({ post, deletePost, setPostId, postId, prependPost }) => {
  const { user, isAuthenticated } = useSelector((state) => state.user);

  const [userData, setUserData] = useState();
  const [reposterData, setReposterData] = useState();
  const [likes, setLikes] = useState(post.likes);

  const dateStr = formatDistance(new Date(post.createdAt), new Date());
  const location = useLocation().pathname;
  const mediaContainerRef = useRef(null);

  const initializeGalleryWidget = () => {
    let galleryWidget = null;
    if (window && post.media?.length > 0 && !galleryWidget) {
      // apply transformations to our media items
      post.media.forEach(item => {
        item.transformation = { "crop": "fill_pad", "background": "#fff", "dpr": "1.0", "fetch_format": item.mediaType === "image" ? "webp" : "mp4" };
        item.thumbnailTransformation = { "crop": "fill", "dpr": "1.0", "fetch_format": "auto", "radius": 7 };

      });
      galleryWidget = window.cloudinary.galleryWidget({
        container: mediaContainerRef.current,
        cloudName: 'dljapfuhk',
        mediaAssets: post.media,
        carouselLocation: 'bottom',
        carouselStyle: post.media.length > 1 ? 'thumbnails' : 'none',
        preLoad: ["image", "video"],
        zoom: false,
        placeholderImage: false,
        analytics: false,
        thumbnailProps: {
          selectedBorderPosition: 'bottom',
          selectedBorderColor: '#1B0035',
          radius: 7
        },
        zoomProps: {
          type: "popup",
          steps: 0,
        },
        videoProps: {
          controls: "all",
          autoplay: false,
          sound: true,
        },
        aspectRatio: "4:4",
      })
    }
    if (mediaContainerRef.current && galleryWidget) {
      galleryWidget.render();
    }
    return galleryWidget;
  };
  useEffect(() => {
    if (!isAuthenticated || !userData) return;
    const mediaElement = mediaContainerRef.current;
    let galleryWidget = null;
    let observer;
    if (post?.commentCount) {
      observer = new IntersectionObserver((entries) => {
        // Only initialize the widget if the media container is visible
        if (entries[0].isIntersecting && !galleryWidget) {
          galleryWidget = initializeGalleryWidget();
          observer.disconnect();
        }
      }, { threshold: 0.2 }); // Trigger when 20% visible

      if (mediaElement) {
        observer.observe(mediaElement);
      }
    } else {
      galleryWidget = initializeGalleryWidget();
    }
    return () => {
      if (galleryWidget?.destroy) {
        galleryWidget.destroy();
      }
      if (post?.commentCount) {
        observer.disconnect();
      }
    };
  }, [isAuthenticated, userData?._id]);



  useEffect(() => {
    const controller = new AbortController();
    try {
      if (post.repostOfUserId) {
        axios.get(`${server}/user/user-info/${post.repostOfUserId}`, { signal: controller.signal }).then((response) => {
          setUserData(response.data.user);
        });
        axios.get(`${server}/user/user-info/${post.userId}`, { signal: controller.signal }).then((response) => {
          setReposterData(response.data.user);
        });

      } else {
        axios.get(`${server}/user/user-info/${post.userId}`, { signal: controller.signal }).then((response,) => {
          setUserData(response.data.user);
        });
      }
    } catch (err) {
      console.log("error", err);
    }
    // Cleanup function
    return () => {
      controller.abort();
    };
  }, [post?.userId]);

  const handleLike = async (e) => {
    e.preventDefault();

    try {
      const token = Cookies.get('token');
      if (!token || !user) {
        toast.error("Please login to like this post");
      }
      // Set up the authorization header with the token
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      };
      axios.put(`${server}/posts/${post._id}/like`, {
        id: user._id,
      }, config);
      if (likes.includes(user._id)) {
        setLikes(likes.filter((id) => id !== user._id));
      } else {
        setLikes(likes.concat(user._id));
      }
    } catch (err) {
      console.log("error", err);
    }
  };
  if (isAuthenticated && user.blocked?.includes(userData?._id)) return;
  if (!isAuthenticated || !userData) return; // stall until user is authenticated and userData is fetched
  return (
    <div className="my-2 py-2 px-0 800px:px-2 bg-white rounded-lg relative">
      {(user?._id === post?.userId || user?.role == "Admin") && (<DeleteModal post={post} deletePost={deletePost} />)}
      <>
        {post.repostOfUserId && (
          <div className=" space-x-2 repost-forum-post-top mb-2 px-2 800px:px-0">
            <div className="flex forum-post-top ">

              <>
                <Link to={`/profile/${reposterData?._id}/posts`} style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    src={`https://res.cloudinary.com/dljapfuhk/image/upload/ar_1.0,c_thumb,g_auto,f_auto/r_max/v1703309315/${reposterData?.avatar?.public_id}.jpg`}
                    className="w-[22px] min-w-[22px] rounded-full mr-[5px]" alt="" hidden={!userData} />
                  <div className="flex items-center w-full">
                    <h3 className="font-bold flex items-center w-full text-sm">{reposterData?.name}</h3>
                    {reposterData?.isPremium && (<BiSolidBadgeCheck className="" size="20" color="#1B0035" />)}
                  </div>
                  <span className="whitespace-nowrap px-2 text-sm"> reposted this</span>
                </Link>
                <p className="text-sm"> - {dateStr}</p>
                {/* {user?._id === post?.userId && (<DeleteModal post={post} deletePost={deletePost} />)} */}
              </>

            </div>
            {post.repostDescription && (
              <div className="w-full text-sm px-5 pb-2">{post?.repostDescription}</div>
            )}

          </div>
        )}

        <div className="flex space-x-2 forum-post-top px-2 800px:px-0">
          <Link to={`/profile/${userData?._id}/posts`} style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={`https://res.cloudinary.com/dljapfuhk/image/upload/ar_1.0,c_thumb,g_auto,f_auto/r_max/v1703309315/${userData.avatar?.public_id}.jpg`}
              className="w-[45px] min-w-[45px] rounded-full mr-[10px]" alt="" hidden={!userData} />

            <div className={`flex flex-col ${userData?.title ? "self-start" : "items-center"}`}>
              <div className="flex items-center">
                <h1 className="font-bold">{userData?.name}</h1>
                {userData?.isPremium && (<BiSolidBadgeCheck className="ml-1" size="20" color="#1B0035" />)}
              </div>
              {userData?.title ?
                (<div className="text-sm  text-[#1B0035] font-medium my-[-5px]">
                  {userData?.title || ' '}
                </div>) : ' '}
            </div>
          </Link>
          {!post.repostOfUserId && (
            <p> - {dateStr}</p>
          )}

        </div>
        <div ref={mediaContainerRef}></div>
        <p style={{ wordWrap: "break-word", marginLeft: "45px" }}>{post.description}</p>

        <div className="forum-post-bottom pt-2 text-sm 800px:text-base justify-around mx-1 800px:mx-8 font-medium">
          <button onClick={handleLike} style={{ display: "flex", alignItems: "center" }}>
            {likes.includes(user?._id) ? (
              <AiFillLike className="mr-2 my-2 cursor-pointer " color="#1B0035" size={20} />
            ) : (
              <AiOutlineLike className="mr-2 my-2 cursor-pointer" color="#1B0035" size={20} />
            )}
            {likes.length || 0} Likes
          </button>
          {setPostId && (
            <>
              <button onClick={() => { setPostId([post?._id, post?.userId]) }} style={{ display: "flex", alignItems: "center" }}>
                {postId && postId[0] == post?._id ? (
                  <AiFillMessage className="mx-2 my-2 cursor-pointer " color="#1B0035" size={20} />
                ) : (
                  <AiOutlineMessage className="mx-2 my-2 cursor-pointer" color="#1B0035" size={20} />
                )}
                {post.commentCount || 0} Comments
              </button>

            </>
          )}


          {user?._id !== post?.userId && user?._id !== post?.repostOfUserId ? (
            <RepostModal post={post} userData={userData} prependPost={prependPost} />
          ) : (
            <span className='flex flex-row mx-2 my-2 cursor-pointer items-center cursor-not-allowed opacity-50'>
              <BiRepost className="mr-2" color="#1B0035" size={25} />
              Repost
            </span>
          )}
          <ShareModal post={post} />
        </div>
      </>

    </div>
  );
};

export default Post;
