import React from "react";
import LoginPage from "../Login";
import Footer from "../../components/Layout/Footer";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import Cookies from 'js-cookie';
import { toast } from "react-toastify";
import ServiceOfferingCard from "../../components/Services/ServiceOfferingCard";
import { useSearchParams } from "react-router-dom";
import ServiceOfferingDetails from "../../components/Services/ServiceOfferingDetails";
import { useLoaderData } from "react-router";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { components } from 'react-select';
import Tooltip from '@atlaskit/tooltip';
import { useNavigate } from "react-router-dom";
import ServiceOfferingReviews from "../../components/Reviews/OfferingReviews";


const MultiValue = (props) => {
  return (
    <Tooltip content={props.data.toolTip}>
      <components.MultiValue {...props} />
    </Tooltip>
  );
};
const animatedComponents = makeAnimated({ MultiValue: MultiValue });

const ServicesHome = () => {
  let { tags } = useParams();
  tags = tags ? encodeURIComponent(tags).replace("%2C", ",") : "";
  let [serviceId, setServiceId] = useSearchParams();
  const serviceData = useLoaderData();
  const [selectedTags, setSelectedTags] = useState([]);
  const [allTags, setAllTags] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {

    setSelectedTags(serviceData?.selectedTags?.map((tag) => {
      return { value: tag._id, label: tag.name, toolTip: tag.description };
    }));
    setAllTags(serviceData?.allTags?.map((tag) => {
      return { value: tag._id, label: tag.name, toolTip: tag.description };
    }));
  }, [tags]);

  const viewingServiceData = serviceData?.serviceOfferings?.find(service => service._id == serviceId.get("id"))
  const visibleOfferings = serviceData?.serviceOfferings?.filter(offering => !offering.hidden);

  return (
    <div className="flex flex-row justify-center 800px:space-x-2 py-6 mt-6">
      <div className={`${!serviceId.get("id") ? "" : "hidden"} 800px:block space-y-2 min-h-[100vh] grow max-w-[600px]`}>
        <div className="flex flex-col bg-white 800px:rounded-lg p-2 shadow-sm border border-gray-200">
          <h2 className="text-xl font-medium text-center pb-4">Filter by Tags</h2>
          <Select
            key={serviceId.get("id")}
            className="w-full"
            closeMenuOnSelect={true}
            components={animatedComponents}
            isMulti
            options={allTags}
            value={selectedTags}
            isClearable={true}
            onChange={(tags) => {
              navigate(
                `/services/${tags.map((tag) => encodeURIComponent(tag.label.replaceAll(" ", "_"))).join(",")}${serviceId.get("id") ? ("?id=" + encodeURIComponent(serviceId.get("id"))) : ""}`
              )
            }}
            placeholder={"Search for tags"}
            noOptionsMessage={() => "No tags are available"}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles


              }),
            }}
            classNames={{
              multiValue: (state) =>
                'border-[#500696] border-[1px] !bg-white !rounded-full',
              multiValueRemove: (state) =>
                '!rounded-r-full ',
              control: (state) =>
                '!shadow-none !rounded-[3px]'
            }}
            theme={(theme) => (
              {
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "#500696",
                  primary25: '#e4d2f4',
                  primary50: '#e4d2f4',
                },
              })}
          />
        </div>


        {!visibleOfferings || visibleOfferings.length == 0 ?
          <div className="bg-white 800px:rounded-lg p-2 shadow-sm border border-gray-200 text-center">
            Not Results found, consider removing a search tag
          </div> : ""
        }

        {visibleOfferings?.map((offering, index) => {
          return <ServiceOfferingCard offering={offering} key={offering._id+" card"} currentUrlTags={tags} urlParams={serviceId} preventScrollReset={true} />;
        })}
      </div>
      <div className={`${!serviceId.get("id") ? "hidden" : ""} 800px:w-[50%] w-full 800px:sticky static  max-w-[600px] h-[80%] top-[80px]`}>
        <div className="space-y-2 w-full static h-full 800px:rounded-lg 800px:overflow-y-scroll 800px:max-h-[80svh]" key={serviceId.get("id")}>

          <ServiceOfferingDetails key={viewingServiceData?._id+"details"} serviceOfferingData={viewingServiceData} setServiceId={setServiceId} currentUrlTags={tags} />
          {viewingServiceData?._id &&
            <ServiceOfferingReviews key={viewingServiceData._id+" review"} serviceOfferingId={viewingServiceData?._id} type="services" />
          }
        </div>
      </div>
    </div>

  );
};
export default ServicesHome;
