import { React, useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import styles from "../../styles/styles";
import { Link } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";

const Singup = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [avatar, setAvatar] = useState("https://res.cloudinary.com/dljapfuhk/image/upload/ar_1.0,c_thumb,g_auto,f_auto/r_max/v1703309315/avatars/ea9gyqgdzmtnfr2cwe9t.jpg");
  const [chatEmailNotifications, setChatEmailNotifications] = useState(false);
  const [forumStep, setForumStep] = useState(0);
  const handleFileInputChange = (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setAvatar(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };
  const deleteAvatar = () => {
    setAvatar("https://res.cloudinary.com/dljapfuhk/image/upload/ar_1.0,c_thumb,g_auto,f_auto/r_max/v1703309315/avatars/ea9gyqgdzmtnfr2cwe9t.jpg");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (forumStep === 0) {
      setForumStep(1);
      return;
    }
    axios
      .post(`${server}/user/create-user`, { name, email, password, avatar, chatEmailNotifications })
      .then((res) => {
        toast.success(res.data.message);
        setName("");
        setEmail("");
        setPassword("");
        deleteAvatar();
        setChatEmailNotifications(false);
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Error your image is too large. Please upload a smaller image.");
        }
      });
  };

  return (
    <div className=" flex flex-col justify-center">
      <div className="mt-8 mx-auto 800px:w-[35%] w-[90%]">
        <div className="bg-white py-8 px-4 shadow rounded-lg px-10">
          <button className={`font-medium text-[#1B0035] text-center pb-8 ${forumStep === 1 ? "block" : "hidden"}`}
            type="button"
            onClick={() => setForumStep(0)}
          >
            &larr; Back to step 1
          </button>
          <h2 className="pb-4 text-center text-3xl font-extrabold text-gray-900" >
            {forumStep === 0 ? "Create a New Account" : "Upload a Profile Photo"}
            <p className={`text-sm font-normal ${forumStep === 0 ? "block" : "hidden"}`}>Start with ConnectSiimply</p>

          </h2>
          <form className=" space-y-6" onSubmit={handleSubmit}>
            <div className={`space-y-6 ${forumStep === 0 ? "block" : "hidden"}`}>
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Full Name
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="name"
                    autoComplete="name"
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-[#1B0035]"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email/Username
                </label>
                <div className="mt-1">
                  <input
                    type="email"
                    name="email"
                    autoComplete="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-[#1B0035]"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1 relative">
                  <input
                    type={visible ? "text" : "password"}
                    name="password"
                    autoComplete="new-password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-[#1B0035]"
                  />
                  {visible ? (
                    <AiOutlineEye
                      className="absolute right-2 top-2 cursor-pointer"
                      size={25}
                      onClick={() => setVisible(false)}
                      color="#1B0035"
                    />
                  ) : (
                    <AiOutlineEyeInvisible
                      className="absolute right-2 top-2 cursor-pointer"
                      size={25}
                      onClick={() => setVisible(true)}
                      color="#1B0035"
                    />
                  )}
                </div>
              </div>
              <div className={`${styles.noramlFlex} justify-between`}>
                <div className={`${styles.noramlFlex}`}>
                  <input
                    type="checkbox"
                    name="notifications"
                    id="notifications-me"
                    className="h-4 w-4 accent-[#1B0035] border-gray-300 rounded "
                    checked={chatEmailNotifications}
                    onChange={(e) => { setChatEmailNotifications(e.target.checked) }}
                  />
                  <label
                    htmlFor="notifications-me"
                    className="ml-2 block text-sm text-gray-900"
                  >
                    Recieve email notifications
                  </label>
                </div>

              </div>
            </div>
            <div id="step-2" className={`${forumStep === 1 ? "block" : "hidden"}`}>
              <label
                htmlFor="avatar"
                className="block text-sm font-medium text-gray-700 text-center"
              >
                Upload a profile photo to MuchMunchie to allow others to recognize you.
                Your profile photo will be publicly viewable and can be changed at any time.
              </label>
              <div className="mx-auto py-4 flex justify-center">
                <div className="relative inline-block">
                  <img
                    src={avatar}
                    alt="avatar"
                    className="w-[150px] h-[150px] object-cover rounded-full overflow-hidden mx-auto my-4"
                  />
                  <button
                    type="reset"
                    onClick={deleteAvatar}
                    className={`absolute top-[5%] right-[5%] text-[#1B0035] rounded-full h-6 w-6 flex items-center justify-center ${avatar.startsWith("http") ? 'hidden' : 'block'}`}
                  >
                    X
                  </button>
                </div>
              </div>
              <label
                htmlFor="file-input"
                className={`cursor-pointer flex items-center justify-center px-4 py-2 border border-[#1B0035] rounded-full shadow-sm text-sm font-medium text-gray-700 bg-[white] ${avatar.startsWith("http") ? 'block' : 'hidden'}`}
              >
                <span>Upload a photo</span>
                <input
                  type="file"
                  name="avatar"
                  id="file-input"
                  accept=".jpg,.jpeg,.png"
                  onChange={handleFileInputChange}
                  className="sr-only"
                />
              </label>
            </div>
            <div>
              <button
                id="signup-submit-button"
                type="submit"
                className="w-full h-[40px] flex justify-center items-center py-2 px-4 border border-transparent text-xl rounded-full text-white bg-[#1B0035]"
              >
                {forumStep === 0 ? "Continue with sign up" : "Complete sign up"}

              </button>
              <div className={`text-center`}>
                <p className="pt-4 text-sm text-gray-600">
                  By signing up you agree to our <Link to="/ConnectSiimply%20Terms%20of%20Service.pdf" target="_blank" className="text-[#1B0035]">Terms of Service</Link>
                </p>
              </div>
            </div>
          </form>

        </div>
        <div className="py-8 px-8 my-4 shadow rounded-lg bg-[#fff] text-center">
          <p className="pb-3">
            Already have an account?
          </p>
          <Link to="/login"
            className="w-full h-[30px] flex justify-center items-center py-2 px-4 border border-transparent text-l rounded-full text-white bg-[#1B0035]"
          >
            Sign in
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Singup;
