import { React, useState, useEffect } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import styles from "../../styles/styles";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from "react-redux";
import {
  loadUser
} from "../../redux/actions/user";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [visible, setVisible] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  // Redirect if user exists
  useEffect(() => {
    if (user) {
      navigate('/'); // redirect already logged in users back home
    }
  }, [user]); // run this whenever user variable changes

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Retrieve the token from the cookies
    const token = Cookies.get('token');
    // Set up the authorization header with the token
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    };
    await axios
      .post(
        `${server}/user/email-forgot-password`,
        {
          email,
        },
        config
      )
      .then((res) => {
        toast.success("An email with a link to reset your password was sent.");
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <div className=" flex flex-col justify-center">
      <div className="mt-8 mx-auto 800px:w-[35%] w-[90%]">
        <div className="bg-white py-8 px-4 shadow rounded-lg px-10">
          <h2 className="pb-4 text-center text-3xl font-extrabold text-gray-900">
            Reset Your Password
          </h2>
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email/Username
              </label>
              <div className="mt-1">
                <input
                  type="email"
                  name="email"
                  autoComplete="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-[#1B0035]"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="w-full h-[40px] flex justify-center items-center py-2 px-4 border border-transparent text-xl rounded-full text-white bg-[#1B0035]"
              >
                Send password reset link
              </button>
            </div>
          </form>
          <div className="text-sm w-full text-center pt-4">
            <Link to="/login"
              className="font-medium text-[#1B0035]"
            >
              Remember your password and want to login?
            </Link>
          </div>
        </div>
        <div className="py-8 px-8 my-4 shadow rounded-lg bg-[#fff] text-center">
          <p className="pb-3">
            Dont yet have an account?
          </p>
          <Link to="/sign-up"
            className="w-full h-[30px] flex justify-center items-center py-2 px-4 border border-transparent text-l rounded-full text-white bg-[#1B0035]"
          >
            Create an account
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
