import { configureStore } from "@reduxjs/toolkit";
import { userReducer } from "./reducers/user";
import { postSlice } from "./reducers/postSlice";


const Store = configureStore({
  reducer: {
    user: userReducer,
    following: postSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default Store;
